import React, {useState, useEffect, useRef} from 'react';
import { TimeframeInputs, EntityTypeInputs, LocationInputs } from './filterInputs';
import { truncate, useWindowSize } from '../util.js';

const FilterBar = (props) => {
  const [ yearRange, setYearRange ] = useState([null, null]);
  const [ currentFilters, setCurrentFilters ] = useState(props.currentFilters);

  // for the mobile filter panel:
  const [ fpOpen, setFpOpen ] = useState(false);
  const [ fpLeft, setFpLeft ] = useState(0);

  const openMFP = (e) => {
    // console.log('openMFP:');
    setFpOpen(true);
    setTimeout(() => {  // delay so the transition applies, because after display/open
      setFpLeft('0');
    }, 15);
  }

  const hideMFP = () => {
    setFpLeft('-100%');
    setTimeout(() => {
      setFpOpen(false);
    }, 250);
  }

  const getFilterPillLabel = (key, d) => {
    /* Returns data formatted for a filter pill according to the type of filter it is */
    switch (key) {
      case 'dateRange':
      case 'date_range':
        if (!d[0]) {
          return 'Before ' + (d[1]);
        } else {
          return (d[0]) + ' - ' + (d[1] || 'Present');
        }
        break;
      default:
        return d;
    }
  }  

  const collapseHdg1 = useRef();
  const collapseHdg2 = useRef();
  const collapseHdg3 = useRef();
  const collapsibles = {
    'collapse1': collapseHdg1,
    'collapse2': collapseHdg2,
    'collapse3': collapseHdg3
  };

  const collapseRef = (id) => {
    // deactivate all but the one clicked;
    // toggle the one clicked
    for (const c in collapsibles) {
      if (c !== id) {
        collapsibles[c]?.current?.classList.toggle('active', false);
      }
    }
    collapsibles[id]?.current?.classList.toggle('active');
  }

  const collapseAll = () => {
    $('.search-filt-container').collapse('hide');
  }

  // keep track of window size:
  const wsize = useWindowSize();
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const isMobileScreenWidth = (w) => (w < 768);

  const clearFiltersAndClosePanel = () => {
    props.clearFilters();
    hideMFP();
  }

  useEffect(() => {
    console.log('window width: '+wsize.width);
    const ismobile = isMobileScreenWidth(wsize.width);
    setIsMobileScreen(ismobile);
    if (fpOpen && !ismobile) {
      console.log('closing MFP');
      setFpOpen(false);
      setFpLeft('-100%');
    }
  }, [wsize]);

  return (
  <div className="lower-page-start">{/* need this lower-page-start to be unique on the page */}
  {/* mobile filter panel */}
  <div
    className={fpOpen ? "" : "d-none"}
    id="mfPanel"
    style={{ left: `${fpLeft}` }}
    aria-hidden={!isMobileScreen}
  >
    <div className="blocker-d" onClick={hideMFP}></div>
    <div className="mfp-base">
      <div className="row m-0 py-3 mfp-heading-row">
        <div className="col mfp-rowfullcol">
          <div className="d-flex align-items-center">
            <div className="icon jd-icon-filter mr-2"></div>
            <div className="d-inline-block dropdown-left-label">Filters</div>
          </div>
          <div className="d-block">
            <button type="button"
              onClick={hideMFP}
              className="btn btn-primary search-filt-close"
            >
              <i className="icon icon-sm button-icon icon-close-button-x icon--teal mr-2"></i> Close
            </button>
          </div>
        </div>
      </div>
    {
      (props.currentFilters && Object.keys(props.currentFilters).length > 0) &&

      <React.Fragment>
      <div className="row m-0 py-1 mfp-heading-row-nb">
        <div className="col mfp-rowfullcol">
            <div className="landing-page__filter-by mr-2">
              <h6 className="dropdown-left-label jdc-nowrap">Filtered By:</h6>
            </div>
            <div className="d-block">
              <button
                className="btn btn-link border-0 filter-clear-all ml-auto"
                type="button"
                onClick={clearFiltersAndClosePanel}
              >
                <div className="mx-1 jdc-nowrap">Clear filters</div>
                <div className="mt-2 icon jd-icon-circle-x"></div>
              </button>
            </div>
        </div>
      </div>
      <div className="row m-0 pt-0 pb-1 mfp-heading-row">
        <div className="col mfp-rowfullcol">
            <div className="landing-page__current-filters pt-0">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col px-0">
                    <div className="d-flex">
                      {
                        (Object.keys(props.currentFilters).length > 0) ?
                          Object.keys(props.currentFilters).map(function (key, idx) {
                            const filterValue = props.currentFilters[key];
                            const filterPillLabel = getFilterPillLabel(key, filterValue);
                            return (
                              <div className="filtered-by-pill" title={filterPillLabel}>
                                { truncate(filterPillLabel, 20) }
                                <a className="ml-2"
                                  href="javascript:;"
                                  aria-label="Remove filter"
                                  // data-toggle="tooltip"
                                  // data-placement="top"
                                  title="Remove filter"
                                  onClick={() => props.clearFilter(key)}
                                >
                                  <span className="icon icon-sm button-icon icon-close-button-x icon--teal"></span>
                                  <span className="sr-only">Remove filter</span>
                                </a>
                              </div>
                            )
                          }) : ('')
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      </React.Fragment>
    }

      <div className="row m-0">
        <div className="col p-0">
          <div className="accordion" id="filtersAccordion">
            <div className="card">
              <div
                className="mfp-collapse-hdg"
                ref={collapseHdg2}
                id="headingTwo"
              >
                <h2 className="mb-0 pb-2">
                  <button
                    className="btn btn-link mfp-heading-text collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    onClick={() => collapseRef("collapse2")}
                  >
                    <i className="icon icon-accordion-carets icon--teal pr-1 mr-1"></i>{" "}
                    Type
                  </button>
                </h2>
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#filtersAccordion"
              >
                <div className="card-body">
                  <EntityTypeInputs
                    showTypeFilter={ props.entityType === 'all' ? true : false }
                    mobileMode={true}
                    applyFilters={props.applyFilters}
                    clearFilter={props.clearFilter}
                    filters={props.currentFilters}
                    aggregations={props.aggregations}
                    count={props.count}
                    collapseFunc={collapseAll}
                  />
                </div>
              </div>
            </div>
            <div className="card">
              <div
                className="mfp-collapse-hdg"
                ref={collapseHdg1}
                id="headingOne"
              >
                <h2 className="mb-0 pb-2">
                  <button
                    className="btn btn-link mfp-heading-text collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                    onClick={() => collapseRef("collapse1")}
                  >
                    <i className="icon icon-accordion-carets icon--teal pr-1 mr-1"></i>{" "}
                    Timeframe
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#filtersAccordion"
              >
                <div className="card-body">
                  <TimeframeInputs
                    mobileMode={true}
                    yearToId="yearToM"
                    yearFromId="yearFromM"
                    yearRange={yearRange}
                    applyFilters={props.applyFilters}
                    clearFilter={props.clearFilter}
                    filters={props.currentFilters}
                    aggregations={props.aggregations}
                    count={props.count}
                    collapseFunc={collapseAll}
                  />
                </div>
              </div>
            </div>
            {/* KLUDGE: to make sure typeahead menu isn't cut off 
                -- the floating suggestion list will be relative to the card; the hidden-overflow
                element needs to be at a lower level in the hierarchy -- the card-body */}
            <div className="card" style={{ overflow: "visible" }}>
              <div
                className="mfp-collapse-hdg"
                ref={collapseHdg3}
                id="headingThree"
              >
                <h2 className="mb-0 pb-2">
                  <button
                    className="btn btn-link mfp-heading-text collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    onClick={() => collapseRef("collapse3")}
                  >
                    <i className="icon icon-accordion-carets icon--teal pr-1 mr-1"></i>{" "}
                    Location
                  </button>
                </h2>
              </div>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#filtersAccordion"
              >
                <div className="card-body" style={{ overflow: "hidden" }}>
                  {/* more of the typeahead kludge */}
                  <LocationInputs
                    mobileMode={true}
                    applyFilters={props.applyFilters}
                    clearFilter={props.clearFilter}
                    filters={props.currentFilters}
                    aggregations={props.aggregations}
                    count={props.count}
                    endpoint={props.endpoint}
                    collapseFunc={collapseAll}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end of mobile filter panel */}

  <div>
    {/* for mobile: Filters button */}
    <div className="landing-filters-mobile d-block d-md-none">
      <div className="row py-3">
        <div className="col mfp-rowfullcol">
          <div className="d-block">
            <button onClick={openMFP} className="btn btn-primary btn-white-bg">
              <i className="icon button-icon icon-sm icon-filter icon--jd-teal mr-2"></i>{" "}
              Filters
            </button>
          </div>
          <div className="d-block pt-2">
            { (props.count < props.pageSize) &&
            <h5 className="mfp-showing">
              <span className="mfp-showing-total"> {props.count}</span>{" "}
              {props.count === 1 ? 'result' : 'results'}
            </h5>
            }
            { !(props.count < props.pageSize) &&
            <h5 className="mfp-showing">
              <span className="mfp-showing-numbers">{props.fromIndex + 1}-{Math.min((props.fromIndex + props.pageSize), props.count)}</span> of
              <span className="mfp-showing-total"> {props.count}</span>{" "}
              {props.count === 1 ? 'result' : 'results'}
            </h5>
            }
          </div>
        </div>
      </div>
    </div>
    {/* end of mobile-only */}

    <div id="collapseFiltersParent" className="d-none d-md-block" aria-hidden={isMobileScreen}>
    <div className={`landing-page__filter-bar ${(props.customClasses || '')} ${(props.barClasses || '')}`}>
      <div className="landing-page__filter-by">
        <div className="icon jd-icon-filter pr-1"></div>
        <h6 className="dropdown-left-label">Filter By:</h6>
      </div>
      <div className="landing-page__filter">
        <button
          type="button"
          className="btn btn-xpandr collapsed"
          id="collapseFiltersTypeButton"
          data-toggle="collapse"
          data-target="#collapseFiltersType"
          aria-expanded="false"
          aria-controls="collapseFiltersType"
        >Type</button>
      </div>
      <div className="landing-page__filter">
        <button
          type="button"
          className="btn btn-xpandr collapsed"
          id="collapseFiltersTimeButton"
          data-toggle="collapse"
          data-target="#collapseFiltersTime"
          aria-expanded="false"
          aria-controls="collapseFiltersTime"
        >Timeframe</button>
      </div>
      <div className="landing-page__filter">
        <button
          type="button"
          className="btn btn-xpandr collapsed"
          id="collapseFiltersLocButton"
          data-toggle="collapse"
          data-target="#collapseFiltersLoc"
          aria-expanded="false"
          aria-controls="collapseFiltersLoc"
        >Location</button>
      </div>
    </div>

    <div className={`collapse search-filt-container ${(props.customClasses || '')} ${(props.rowCustomClasses || '')}`} id="collapseFiltersType" data-parent="#collapseFiltersParent">
      <EntityTypeInputs
        showTypeFilter={ props.entityType === 'all' ? true : false }
        applyFilters={props.applyFilters}
        clearFilter={props.clearFilter}
        filters={props.currentFilters}
        aggregations={props.aggregations}
        count={props.count}
        collapseFunc={collapseAll}
      />
    </div>

    <div className={`collapse search-filt-container ${(props.customClasses || '')} ${(props.rowCustomClasses || '')}`} id="collapseFiltersTime" data-parent="#collapseFiltersParent">
      <TimeframeInputs
        yearToId="yearToM"
        yearFromId="yearFromM"
        yearRange={yearRange}
        applyFilters={props.applyFilters}
        clearFilter={props.clearFilter}
        filters={props.currentFilters}
        aggregations={props.aggregations}
        count={props.count}
        collapseFunc={collapseAll}
      />
    </div>

    <div className={`collapse search-filt-container ${(props.customClasses || '')} ${(props.rowCustomClasses || '')}`}
      id="collapseFiltersLoc"
      data-parent="#collapseFiltersParent"
    >
      <LocationInputs
        applyFilters={props.applyFilters}
        clearFilter={props.clearFilter}
        filters={props.currentFilters}
        aggregations={props.aggregations}
        count={props.count}
        endpoint={props.endpoint}
        collapseFunc={collapseAll}
      />
    </div>

    {
      (props.currentFilters && Object.keys(props.currentFilters).length > 0) &&

    <div className={`landing-page__filtered-bar landing-filters-bt ${(props.customClasses || '')} ${(props.barClasses || '')}`}>
      <div className="landing-page__filter-by mr-2">
        <h6 className="dropdown-left-label jdc-nowrap">Filtered By:</h6>
      </div>
      <div className="landing-page__current-filters">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col px-0">
              <div className="d-flex">
                {
                  (Object.keys(props.currentFilters).length > 0) ?
                    Object.keys(props.currentFilters).map(function (key, idx) {
                      const filterValue = props.currentFilters[key];
                      const filterPillLabel = getFilterPillLabel(key, filterValue);
                      return (
                        <div className="filtered-by-pill" title={filterPillLabel}>
                          { truncate(filterPillLabel, 20) }
                          <a className="ml-2"
                            href="javascript:;"
                            aria-label="Remove filter"
                            // data-toggle="tooltip"
                            // data-placement="top"
                            title="Remove filter"
                            onClick={() => props.clearFilter(key)}
                          >
                            <span className="icon icon-sm button-icon icon-close-button-x icon--teal"></span>
                            <span className="sr-only">Remove filter</span>
                          </a>
                        </div>
                      )
                    }) : ('')
                }
              </div>
            </div>
            <div className="d-block col-xs-6 col-sm-4 col-md-3 ml-auto">
              <button
                className="btn btn-link border-0 filter-clear-all"
                type="button"
                onClick={props.clearFilters}
              >
                <div className="mx-1 jdc-nowrap">Clear filters</div>
                <div className="mt-2 icon jd-icon-circle-x"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    }

  </div>
  </div>
  </div>
  )
}

export { FilterBar };