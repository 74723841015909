import React, { useState } from 'react';

import { useElasticSearch, essaysHomeContextToQuery } from '../util.js';

function EssayHomeView(props) {

  const [endpoint, setEndpoint] = useState(props.endpoint)
  const [query, setQuery] = useState(essaysHomeContextToQuery())

  const results = useElasticSearch(endpoint,query)

  const histories = results?.hits?.hits?.filter( (hit,idx) => hit._source.classified_as.some( item => item.label == 'History' ) ).sort( (a,b) => Number(a._source.sequence) - Number(b._source.sequence) ) ?? []
  const approaches = results?.hits?.hits?.filter( (hit,idx) => hit._source.classified_as.some( item => item.label == 'Project Approach' ) ).sort( (a,b) => Number(a._source.sequence) - Number(b._source.sequence) ) ?? []

  return (
      <React.Fragment>
          <div className="home-essay-flex">
            <div className="home-essay-column">
              <div className="home-essay-column-hdr hdr1">
                <h3>The history</h3>
                <div className="home-essay-column-cat">
                  Essays
                </div>
              </div>
              <div className="home-essay-column-main">
                {
                  histories.map( (hit,idx) => 
                    <React.Fragment key={idx}>
                      <p className="home-essays-link-p"><a href={hit._id.replace('http://www.collectionbrowse.org','')}>{ hit._source.primary_name.label ?? ''}</a></p>
                      <p className={`pub-byline ${hit._source?.author?.length > 0 ? '' : 'd-none' }`}>By {hit._source?.author?.map( auth => auth.label ).join(', ') ?? '' }{ hit._source?.creation_date?.date_label ? ` | ${hit._source?.creation_date?.date_label}` : ''}</p>
                      <p className="home-essays-p">{hit._source?.description?.html ?? ''}</p>
                    </React.Fragment>
                    )
                }
              </div>
            </div>
          </div>
          <div className="home-essay-flex">
            <div className="home-essay-column">
              <div className="home-essay-column-hdr hdr2">
                <h3>Project approach</h3>
                <div className="home-essay-column-cat">
                  Essays
                </div>
              </div>
              <div className="home-essay-column-main">
                {
                  approaches.map( (hit,idx) => 
                    <React.Fragment key={idx}>
                      <p className="home-essays-link-p"><a href={hit._id.replace('http://www.collectionbrowse.org','')}>{ hit._source.primary_name.label ?? ''}</a></p>
                      <p className={`pub-byline ${hit._source?.author?.length > 0 ? '' : 'd-none' }`}>By {hit._source?.author?.map( auth => auth.label ).join(', ') ?? '' }{ hit._source?.creation_date?.date_label ? ` | ${hit._source?.creation_date?.date_label}` : ''}</p>
                      <p className="home-essays-p">{hit._source?.description?.html ?? ''}</p>
                    </React.Fragment>
                    )
                }
              </div>
            </div>
          </div>
      </React.Fragment>
  )
}

export {EssayHomeView}