import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useWindowSize, useDimension } from '../util.js';

// SLIDER: adapted from 'multi carousel', found online -- multi because any number of them can coexist on the page

// NOTE: assumes equal-width items. 

const MultiCarouselR = (props) => {
    const displaySlots = props.displaySlots || 5;   // equivalent to the # of data items that can be displayed before arrow-buttons need to be shown
    const minItemWidth = 60;
    // each item has margin 12px on either side, plus some px for border & highlight
    const spaceAroundItem = 34; // image width will be itemWidthN minus this
    const scrollButtonsWidth = 64;  // space taken up by both the scroller buttons
    const initItemWidth = props.variableWidth && props.maxWidth
        ? Math.max(minItemWidth, Math.floor((props.maxWidth - scrollButtonsWidth) / displaySlots))
        : (props.itemWidth ?? 112);
    const [ itemWidthN, setItemWidthN ] = useState(initItemWidth);
    const alignMode = props.alignMode || 'left';
    const favoriteViewIndex = props.preferIndex || 1;

    // used with center mode:
    const ctrDisplayIdx = Math.floor(displaySlots / 2);
    const initEmptySlots = Math.max(0, Math.floor((displaySlots - props.children.length) / 2));
    // max & minSlot are the constraints upon the display pos. of the first data item
    // slot pos. * itemWidthN == slideX
    const maxSlot = Math.max(0, (displaySlots - props.children.length));
    const minSlot = props.children.length >= displaySlots
        ? (displaySlots - props.children.length)
        : initEmptySlots;
    // console.log('initEmptySlots: ' + initEmptySlots + '; trailing: ' + trailingEmptySlots + '; total children: ' + props.children.length);

    // to use the center item as selected:
    // const [ selDataIdx, setSelDataIdx ] = useState(ctrDisplayIdx - initEmptySlots);

    const [ selDataIdx, setSelDataIdx ] = useState(props.selectedIdx ?? -1);

    // used only in center mode:
    const [ displaySlotIdx, setDisplaySlotIdx ] = useState(initEmptySlots);

    const wsize = useWindowSize();

    // slideX: can range from 0 down to (mcvw - listWidth)
    const [ slideX, setSlideX ] = useState(alignMode === 'left' ? 0 : (initEmptySlots * initItemWidth));
    // const [ mcvw, setMcvw ] = useState(500);

    const adjustItemWidth = (w) => {
        const newW = Math.max(minItemWidth, Math.floor(w / displaySlots));
        // console.log('adjust item width to: ' + newW);
        setItemWidthN(newW);
        if (alignMode === 'center') {
            setSlideX(displaySlotIdx * newW);   // INVARIANT: slideX == display slot * item width
        }
    }

    // const mcRef = useRef();
    // const mcRef = useCallback((node) => {
    //     console.log('mcRef callback');
    //     if (node) {
    //         console.log('node width: ' + node.offsetWidth);
    //         if (mcvRef.current) {
    //             console.log('mcvw: ' + mcvRef.current.offsetWidth);
    //             setMcvw(mcvRef.current.offsetWidth);
    //             if (props.variableWidth && props.maxWidth) {
    //                 adjustItemWidth(mcvRef.current.offsetWidth);
    //             }
    //         }
    //     }
    // }, []);
    const mcvRef = useRef();
    const [ mcvw, mcvH ] = useDimension(mcvRef);
    useEffect(() => {
        // console.log('mcvw EFFECT');
        // console.log(mcvRef.current);
        if (mcvRef.current) {
            // console.log('new mcvw = ' + mcvRef.current.offsetWidth);
            // setMcvw(mcvRef.current.offsetWidth);
            if (props.variableWidth && props.maxWidth) {
                adjustItemWidth(mcvRef.current.offsetWidth);
            }
        }
    }, [mcvw]);
    // useEffect(() => {
    //     console.log('mcvRef EFFECT');
    //     console.log(mcvRef);
    //     if (mcvRef.current) {
    //         console.log('new mcvw = ' + mcvRef.current.offsetWidth);
    //     }
    // }, [mcvRef]);
    // useEffect(() => {
    //     console.log('mcvRef.current EFFECT');
    //     console.log(mcvRef.current);
    //     if (mcvRef.current) {
    //         console.log('new mcvw = ' + mcvRef.current.offsetWidth);
    //     }
    // }, [mcvRef.current]);

    // useEffect(() => {
    //     if (mcvRef.current) {
    //         console.log('effect of mcvRef: mcvw = ' + mcvRef.current.offsetWidth);
    //         console.log('mcv bcr w = ' + mcvRef.current.getBoundingClientRect().width);
    //         console.log(mcvRef.current);
    //         setMcvw(mcvRef.current.offsetWidth);
    //         if (props.variableWidth && props.maxWidth) {
    //             adjustItemWidth(mcvRef.current.offsetWidth);
    //         }
    //     } else {
    //         console.log('effect: no mcvRef.current');
    //     }
    // }, [mcvRef, mcvRef.current]);

    // useEffect(() => {
    //     console.log(mcvRef.current);
    //     if (mcvRef.current) {
    //         console.log('new mcvw = ' + mcvRef.current.offsetWidth);
    //         setMcvw(mcvRef.current.offsetWidth);
    //         if (props.variableWidth && props.maxWidth) {
    //             adjustItemWidth(mcvRef.current.offsetWidth);
    //         }
    //     }
    // }, [wsize]);

    const moveCarousel = (dir, s) => {
        // console.log('moveCarousel:' + dir);
        const newSlideX = (dir === 0) ? slideX - s * itemWidthN : slideX + s * itemWidthN;
        const newDisplaySlotIdx = (dir === 0) ? displaySlotIdx - s : displaySlotIdx + s;
        // console.log('newDisplaySlotIdx = ' + newDisplaySlotIdx);
        setSlideX(newSlideX);
        setDisplaySlotIdx(newDisplaySlotIdx);
    }

    const itemSelect = (e, idx) => {
        // console.log('itemselect (01): '+idx);
        setSelDataIdx(idx);
    }

    const itemFocus = (e, idx) => {
        // MC tabbing notes:
        // . slideX will either be 0 or negative; slideX / itemWidthN is number of places
        // . try to keep slider stable -- don't move unless necessary
        // . keep 1-2 items before the current and the rest after
        // . in 'left' mode, aim for 2nd position, subject to sliding bounds

        // if (idx === selDataIdx) return;

        // console.log('itemFocus: setting sel idx to ' + idx);
        setSelDataIdx(idx);
        if (alignMode === 'left') {
            const slideXMin = (Math.floor(mcvw / itemWidthN) * itemWidthN - listWidth);
            // what index has 2nd displayed position?
            const place2Index = -slideX / itemWidthN + favoriteViewIndex;
            // if idx < p2I, need to move right, unless slideX is 0 or more
            if (idx < place2Index && slideX < 0) {
                const steps = Math.min(Math.abs(slideX) / itemWidthN, Math.abs(idx - place2Index));
                // actually, if fave index is 1 and we're at 0, so we'd be moving 1 step to the right, override & do nothing
                // - it just doesn't feel right
                if (!(favoriteViewIndex === 1 && steps === 1)) {
                    moveCarousel(1, steps);
                }
            } else {
                // if idx > p2I, need to move left, unless slideX is slideXMin or less
                // console.log('itemFocus: place2idx == ' + place2Index + '; slidex: ' + slideX + '; slidexmin: ' + slideXMin);
                if (idx > place2Index && slideX > slideXMin) {
                    const steps = Math.min((slideX - slideXMin) / itemWidthN, Math.abs(idx - place2Index));
                    moveCarousel(0, steps);
                }
            }
        } else if (alignMode === 'center') {
            const newDisplaySlotIdx = Math.max(minSlot, Math.min(maxSlot, (ctrDisplayIdx - idx + initEmptySlots)));
            const newSlideX = newDisplaySlotIdx * itemWidthN;
            // console.log('setting selDataIdx to ' + idx + '; new slot idx = ' + newDisplaySlotIdx + '; new slidex = ' + newSlideX + '; listWidth = ' + listWidth);
            // console.log('minslot - maxslot = ' + minSlot + ' - ' + maxSlot + '; ctrDisplayIdx = ' + ctrDisplayIdx + '; trailingEmptySlots = ' + trailingEmptySlots);
            // only move if necessary
            if (props.children.length > displaySlots) {
                setSlideX(newSlideX);
                setDisplaySlotIdx(newDisplaySlotIdx);
            }
        }
    }

    const moreToTheLeft = () => {
        return (slideX < 0);
    }

    const moreToTheRight = () => {
        // console.log('slideX: '+ slideX +'; listW: '+ listWidth +'; mcvw: '+ mcvw);
        return (mcvw !== 0 && slideX + listWidth > mcvw);
    }

    // add the focus function to each child, that will be called when it receives the focus
    const elements = React.Children.toArray(props.children).map((hit, idx) => {
        return React.cloneElement(hit, {
            focusfunc: (e) => itemFocus(e, idx),
            setSelFunc: (e) => itemSelect(e, idx),
            key: idx,
            idx: idx,
            selDataIdx: selDataIdx,
            imgWidth: (props.variableWidth && props.maxWidth) ? (itemWidthN - spaceAroundItem) : props.imgWidth,
            itemWidth: itemWidthN
        });
    });

    const listWidth = itemWidthN * elements.length;

    return (
        <div className={`MultiCarousel ${alignMode === 'left' ? 'alignleft' : (alignMode === 'center' ? 'ml-auto mr-auto d-flex align-items-center' : '')}`}
            style={(props.maxWidth ? {maxWidth: `${props.maxWidth}px`} : null)}
        >
            <button
                type="button"
                className={`btn p-0 btn-link sliding-button sliding-button-left ${props.buttonClass ? props.buttonClass : 'sliding-button-midlevel'} ${moreToTheLeft() || props.alwaysShowButtons ? '' : 'hid'}`}
                onClick={(e) => moveCarousel(1, 1)}
            >
                <div className="icon icon-big-carat-left">
                    <span className="sr-only">Previous</span>
                </div>
            </button>
            <div className="MultiCarouselView" ref={mcvRef}>
                <div className={`MultiCarouselInner ${alignMode === 'center' ? 'd-flex align-items-center' : ''}`} style={{width: `${listWidth}px`, transform: `translateX(${slideX}px)`}}>
                {elements}
                </div>
            </div>
            <button
                type="button"
                className={`btn p-0 btn-link sliding-button sliding-button-right ${props.buttonClass ? props.buttonClass : 'sliding-button-midlevel'} ${moreToTheRight() || props.alwaysShowButtons ? '' : 'hid'}`}
                onClick={(e) => moveCarousel(0, 1)}
            >
                <div className="icon icon-big-carat-right">
                    <span className="sr-only">Next</span>
                </div>
            </button>
        </div>
    )
}

export default MultiCarouselR;