import React, { useState } from 'react';
import { useElasticSearch, searchContextToQuery } from '../util.js';

function HomePageView(props) {
    const [ endpoint, setEndpoint ] = useState(props.endpoint);
    const [ context, setContext ] = useState(searchContextToQuery('', {}, 'all', 0));
    const results = useElasticSearch(endpoint, context);

    const countPeople = results?.aggregations?.entity_type?.buckets?.filter(item => item.key.label === 'Person').map(item => item.doc_count)[0];
    const countArtworks = results?.aggregations?.entity_type?.buckets?.filter(item => item.key.label === 'Artwork').map(item => item.doc_count)[0];
    const countArtists = 266; // results?.aggregations?.entity_type?.buckets?.filter(item => item.key.label === 'Artwork').map(item => item.doc_count)[0];
    const countDocuments = results?.aggregations?.entity_type?.buckets?.filter(item => item.key.label === 'Document').map(item => item.doc_count)[0];
    const countLocations = results?.aggregations?.entity_type?.buckets?.filter(item => item.key.label === 'Location').map(item => item.doc_count)[0];
    const countOrgs = results?.aggregations?.entity_type?.buckets?.filter(item => item.key.label === 'Group').map(item => item.doc_count)[0];

    return (
        <React.Fragment>
        <div class="col-6 col-sm-4 col-lg-2 px-0">
            <a class="home-relations-card" href="/people/">
            <div class="home-relations-card-top">
                <div class="pt-2 pb-3 icon icon-lg icon--jd-grey jd-icon-users-mask"></div>
                <div class="home-rel-big-count">{ countPeople ?? 0 }</div>
                <div class="home-rel-card-diamond">
                <span class="icon icon-gray-diamond"></span>
                </div>
            </div>
            <div class="home-relations-card-bottom">
                People
            </div>
            </a>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 px-0">
            <a class="home-relations-card" href="/people/?f.classification=Artist">
            <div class="home-relations-card-top">
                <div class="pt-2 pb-3 icon icon-lg icon--jd-grey jd-icon-palette"></div>
                <div class="home-rel-big-count">{ countArtists ?? 0 }</div>
                <div class="home-rel-card-diamond">
                <span class="icon icon-gray-diamond"></span>
                </div>
            </div>
            <div class="home-relations-card-bottom">
                Artists
            </div>
            </a>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 px-0">
            <a class="home-relations-card" href="/artwork/">
            <div class="home-relations-card-top">
                <div class="pt-2 pb-3 icon icon-lg icon--jd-grey jd-icon-frame"></div>
                <div class="home-rel-big-count">{ countArtworks ?? 0 }</div>
                <div class="home-rel-card-diamond">
                <span class="icon icon-gray-diamond"></span>
                </div>
            </div>
            <div class="home-relations-card-bottom">
                Artworks
            </div>
            </a>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 px-0">
            <a class="home-relations-card" href="/documents/">
            <div class="home-relations-card-top">
                <div class="pt-2 pb-3 icon icon-lg icon--jd-grey jd-icon-doc-mask"></div>
                <div class="home-rel-big-count">{ countDocuments ?? 0 }</div>
                <div class="home-rel-card-diamond">
                <span class="icon icon-gray-diamond"></span>
                </div>
            </div>
            <div class="home-relations-card-bottom">
                Documents
            </div>
            </a>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 px-0">
            <a class="home-relations-card" href="/location/">
            <div class="home-relations-card-top">
                <div class="pt-2 pb-3 icon icon-lg icon--jd-grey icon-pin-mask"></div>
                <div class="home-rel-big-count">{ countLocations ?? 0 }</div>
                <div class="home-rel-card-diamond">
                <span class="icon icon-gray-diamond"></span>
                </div>
            </div>
            <div class="home-relations-card-bottom">
                Locations
            </div>
            </a>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 px-0">
            <a class="home-relations-card" href="/organizations/">
            <div class="home-relations-card-top">
                <div class="pt-2 pb-3 icon icon-lg icon--jd-grey jd-icon-org-mask"></div>
                <div class="home-rel-big-count">{ countOrgs ?? 0 }</div>
                <div class="home-rel-card-diamond">
                <span class="icon icon-gray-diamond"></span>
                </div>
            </div>
            <div class="home-relations-card-bottom">
                Organizations
            </div>
            </a>
        </div>
        </React.Fragment>
    )
}

export {HomePageView}