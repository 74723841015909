import React, { useState, useRef, useEffect, useContext } from 'react';
import { useElasticSearch, documentsContextToQuery } from './util.js';
import { ResultsPageControl, ResultsView, RESULTS_MODE } from './results.js';

const DocumentsTab = (props) => {

  const [ entityType, setEntityType ] = useState('Document');
  const [ endpoint, setEndpoint ] = useState(props.endpoint);
  const [ queryString, setQueryString ] = useState('');
  const [ filters, setFilters ] = useState(props.filters);
  // const [ aggregations, setAggregations ] = useState({});
  const [ ident, setIdent ] = useState(props.ident)
  const [ query, setQuery ] = useState(documentsContextToQuery(ident));

  const results = useElasticSearch(endpoint, query);

  const [ count, setCount ] = useState(0);
  const [ fromIndex, setFromIndex ] = useState(0);
  const [ pageSize, setPageSize ] = useState(50);
  const [ sortSetting, setSortSetting ] = useState('nameaz');
  const [ resMode, setResMode ] = useState(RESULTS_MODE.GRID);  // default mode

  useEffect(() => {
      if (results === null) { return; }
      setCount(results?.hits?.total?.value ?? 0);
  }, [results]);

  const clickForListMode = () => {
    setResMode(RESULTS_MODE.LIST);
  }

  const clickForGridMode = () => {
    setResMode(RESULTS_MODE.GRID);
  }

  function changePage(newVal) {
    if ( newVal < count ) {
        setFromIndex(newVal);
        setQuery(searchContextToQuery(queryString,filters,entityType,pageSize,newVal));
        // setUrlFromState()
    }
  }

  function changeSort(newVal) {
      setSortSetting(newVal);
      // set page to first
      setFromIndex(0);
      // console.log('setting sort to ' + newVal);
      let sort_term = "primary_name.label";
      let sort_order = "asc";
      switch (newVal) {
        case 'nameza':
          sort_order = 'desc';
          break;
        case 'creatornameaz':
          sort_term = 'creator.label';
          sort_order = 'asc';
          break;
        case 'lifedates':
          sort_term = 'birth.date_label';
          sort_order = 'asc';
          break;
      }
      setQuery(searchContextToQuery(queryString,filters,entityType,pageSize,0,sort_term,sort_order));
      // setUrlFromState()
  }

    return (
      <div
        className={`tab-pane clearfix fade px-0 mx-0 ${props.show ? 'show' : ''} ${props.active ? 'active' : ''}`}
        id="documents"
        role="tabpanel"
        aria-labelledby="documents-tab"
        aria-expanded={props.active}
        aria-hidden={!props.active}
      >
        <div className="container-fluid p-0 m-0">
        <div className="row mx-0 bg-white">
          <div className="col px-4">
            <ResultsPageControl changePage={changePage} changeSort={changeSort} sortSetting={sortSetting} count={count} fromIndex={fromIndex} pageSize={pageSize} mode={resMode} resultsModeList={clickForListMode} resultsModeGrid={clickForGridMode} type={props.type} />
            <ResultsView results={results} count={count} mode={resMode} searchStr={queryString} />                
            <ResultsPageControl changePage={changePage} changeSort={changeSort} sortSetting={sortSetting} count={count} fromIndex={fromIndex} pageSize={pageSize} mode={resMode} basiclayout={true} type={props.type} />
          </div>
        </div>
        </div>
      </div>
    )
}

export { DocumentsTab };