import React, { useState, useEffect, useRef } from 'react';
import TLEvent from './TLEvent';

const TLEventCluster = (props) => {
    const dummyDateStrL2 = 'April - June 1943';
    const dummyDescL2 = 'Negotiations to acquire works from the Schloss collection';
    const dummyDescL3 = 'lorem ipsum vinar sic tempor... [event cluster subheading/description] with a superduperextracallifragilisticexpialidociouslylong word! just for fun. Plus lots more words so we can see how it behaves with more than two lines.';

    const clusterAccItemRef = useRef();
    const [ activeEventRef, setActiveEventRef ] = useState(null);

    let eventArray = [{}, {}, {}, {}, {}, {}, {}];
    const [ expandedArr, setExpandedArr ] = useState([]);

    useEffect(() => {
        let newArr = [];
        eventArray.forEach(() => {
            newArr.push(false);
        })
        setExpandedArr(newArr);
    }, []);

    const setExpandedIdx = (idx, exp) => {
        const newArr = expandedArr.map((val, i) => (i === idx) ? exp : val);
        setExpandedArr(newArr);
        console.log(expandedArr);
    }

    const clickCluster = () => {
        // expanded toggles -- in mobile mode only
        if (props.isMobileScreen) {
            if (props.selectedClusterInGroup !== props.myIdx) {
                // may need to auto-scroll when opening new cluster
                if (props.selectedClusterInGroup >= 0 && props.activeClusterRef?.current && props.myIdx > props.selectedClusterInGroup) {
                    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                    const newCollapsedHeight = clusterAccItemRef.current.offsetHeight;
                    const oldHeight = props.activeClusterRef.current.offsetHeight;
                    // we presume that the new group header height is what the old group header height will become after collapse
                    // (these headers have limited height when collapsed, full normal height when expanded)
                    // therefore, deltaY is the difference between the whole height of the old & the new header height
                    const deltaY = oldHeight - newCollapsedHeight;
                    const scrollAmt = Math.min(deltaY, scrollTop);  // can't scroll up any further than page is scrolled down
                    window.scrollBy(0, -scrollAmt);
                }
            }
        }
        props.selectCluster(props.parentIdx, props.myIdx, clusterAccItemRef);
    }

    // which event is open in the L3 level? -1 means none
    // this is relevant to mobile layout -- for full-size, we can have multiple Events open
    const [ eventOpenIdx, setEventOpenIdx ] = useState(-1);
    const [ scrollWindowBy, setScrollWindowBy ] = useState(0);

    useEffect(() => {
        // console.log('useEffect: new props.activeClusterDetail = '+props.activeClusterDetail+'; expanded = '+expanded);
        setEventOpenIdx(-1);
        // setExpanded(props.groupClusterId === props.activeClusterDetail);
    }, [props.activeClusterDetail]);

    // mobile only:
    useEffect(() => {
        // console.log('useEffect: eventOpenIdx = '+eventOpenIdx+'; scrollWindowBy = '+scrollWindowBy);
        if (scrollWindowBy !== 0) {
            window.scrollBy(0, scrollWindowBy);
            // window.scrollTo({
            //     top: scrollWindowBy
            // });
            setScrollWindowBy(0);
        }
    }, [eventOpenIdx]);

    return (
        <div className={`tl-event-cluster ${(props.selectedClusterInGroup === props.myIdx) ? 'selected' : ''} ${(props.isMobileScreen ? 'ismobile' : '')}`}
            ref={clusterAccItemRef}
        >
            { /*
            * NOTE: For mobile mode, use accordion collapsible; need:
            * -- data-toggle, data-target, aria-expanded, aria-controls
            * For full-size mode, use tabs; need:
            * -- role, aria-selected, aria-controls
            */ }
            <div className="accordion-header d-flex"
                onClick={clickCluster}
                id={`collapseTLEventViewButton-${props.groupClusterId}`}
                role={(props.isMobileScreen ? null : 'tab')}
                aria-expanded={(props.isMobileScreen ? (props.selectedClusterInGroup === props.myIdx) : null)}
                aria-selected={(props.isMobileScreen ? null : (props.selectedClusterInGroup === props.myIdx))}
                aria-controls={(props.isMobileScreen ? `detailmm-accordion-collapse-${props.groupClusterId}` : `collapseTLEventView-${props.groupClusterId}`)}
            >
                <div className="tl-event-cluster-subcontainer">
                    <div className="mr-auto">
                        <h4 className="tl-event-cluster-hdg">
                            {props.clusterData.date_label}
                        </h4>
                        <div className="tl-event-cluster-subhead">
                            {props.clusterData.primary_name.label ?? 'FIXME'}
                        </div>
                        <div className="tl-event-cluster-desc d-block d-md-none">
                            {props.clusterData.description ?? 'FIXME'}
                        </div>
                    </div>
                    <div className="tl-event-cluster-detail-button">
                        <button
                            type="button"
                            className={`btn btn-link tl-event-view-button ${(props.selectedClusterInGroup === props.myIdx ? '' : 'collapsed')}`}
                        >{`${props.clusterData.part.length} events`}
                            <span className={`icon button-icon icon--white ${(props.isMobileScreen ? ((props.selectedClusterInGroup === props.myIdx) ? 'icon-carat-up' : 'icon-carat-down') : 'icon-carat-right')}`}></span>
                        </button>
                    </div>
                </div>
                <div className="tl-event-cluster-addon d-none d-md-block"></div>
            </div>
            <div className="accordion-body d-flex d-md-none">
                <div className={`collapse ${(props.selectedClusterInGroup === props.myIdx) ? 'show' : ''} w-100`}
                    id={`detailmm-accordion-collapse-${props.groupClusterId}`}
                    aria-labelledby={`collapseTLEventViewButton-${props.groupClusterId}`}
                >
                    { /* mobile mode only: the TLEvents that would otherwise appear in TLEventClusterDetail: */ }
                    <div className={`accordion tl-detail-big-acc ismobile`} id={`TLDetailListMM-${props.groupClusterId}`}>
                        {
                            (props.clusterData.part) ? props.clusterData.part?.map((hit, idx) => {
                                return (
                                    <TLEvent
                                        parentAccId={props.groupClusterId}
                                        groupClusterEventId={`${props.groupClusterId}-${idx}`}
                                        showingClusterDetail={props.showingClusterDetail}
                                        activeClusterDetail={props.activeClusterDetail}
                                        defaultPerspTab={-1}
                                        activeGroup={props.activeGroup}
                                        activeEventRef={activeEventRef}
                                        setActiveEventRef={setActiveEventRef}
                                        scrollWindowBy={scrollWindowBy}
                                        setScrollWindowBy={setScrollWindowBy}
                                        isMobileScreen={props.isMobileScreen}
                                        myIdx={idx}
                                        key={`TLEventLLM-${props.groupClusterId}-${idx}`}
                                        eventOpenIdx={eventOpenIdx}
                                        setEventOpenIdx={setEventOpenIdx}
                                        setExpandedIdx={setExpandedIdx}
                                        nextExpanded={(idx < expandedArr.length-1 && expandedArr[idx+1])}
                                        eventData={hit ?? {}} 
                                    />
                                )
                            }) : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TLEventCluster;