import React, { useState, useEffect, useRef } from 'react';
import TLEvent from './TLEvent';

const TLEventClusterDetail = (props) => {

    const [ expandedArr, setExpandedArr ] = useState(Array(props.clusterData.length).fill(false));

    const setExpandedIdx = (idx, exp) => {
        const newArr = expandedArr.map((val, i) => (i === idx) ? exp : val);
        setExpandedArr(newArr);
        console.log(expandedArr);
    }

    const contentPaneRef = useRef(null);
    const hcpRef = useRef(null);
    const [ hcOpen, setHcOpen ] = useState(false);
    const [ hcRight, setHcRight ] = useState('-100%');
    const [ hcph, setHcph ] = useState(0);
    const mainHcMinHeight = 'calc(100% - 180px)';
    const [ hcMinHt, setHcMinHt ] = useState(mainHcMinHeight);

    const openHCPanel = () => {
        setHcOpen(true);
        setTimeout(() => {
          setHcRight('0');
        }, 8);
    }

    const hideHCPanel = () => {
        setHcRight('-100%');
        setTimeout(() => {
            setHcOpen(false);
        }, 25);
    }

    const [ activeEventRef, setActiveEventRef ] = useState(null);

    // which event is open in the L3 level? -1 means none
    const [ eventOpenIdx, setEventOpenIdx ] = useState(-1);
    useEffect(() => {
        setEventOpenIdx(-1);
    }, [props.activeClusterDetail]);

    const adjustHeightForHC = () => {
        if (hcOpen && hcpRef.current) {
            if (contentPaneRef.current) {
                setHcph(hcpRef.current.offsetHeight);
            }
            setHcMinHt(mainHcMinHeight);
        } else {
            setHcph(0);
        }
    }

    // when the HC opens, we may need to add some height to the panel so it's tall enough to show all of the HC
    useEffect(() => {
        adjustHeightForHC();
    }, [hcOpen]);

    // also when the display width changes
    useEffect(() => {
        adjustHeightForHC();
    }, [props.screenWidth]);

    // also when anything collapses or expands
    const handleHiddenBsCollapseShow = (e) => {
        // delay because the calculation of content height should wait till after collapsing/expanding
        setTimeout(() => {
            adjustHeightForHC();
        }, 50);
    }

    return (
        <div className={`tl-detail-view tab-pane clearfix fade ${props.showingClusterDetail === props.groupClusterId ? 'show' : ''} ${props.activeClusterDetail === props.groupClusterId ? 'active' : ''}`}
            id={`collapseTLEventView-${props.groupClusterId}`}
            role="tabpanel"
            aria-labelledby={`collapseTLEventViewButton-${props.groupClusterId}`}
            aria-expanded={props.activeClusterDetail === props.groupClusterId}
            aria-hidden={props.activeClusterDetail !== props.groupClusterId}
        >
        <div className="tl-detail-container"
            id={`collapseTLEventView-${props.groupClusterId}-content`}
        >
            <div className="tl-detail-view-header">
                <div className="tl-detail-header-dates">
                    { props.clusterData.date_label ?? "FIXME" }
                </div>
                <div className="tl-detail-heading">
                    { props.clusterData.primary_name?.label ?? "FIXME" }
                </div>
                <div className="tl-detail-header-desc">
                    { props.clusterData.description ?? "FIXME" }
                </div>
            </div>
            <div className="tl-detail-view-body">
                { // don't show the HC button if there is no context data to show
                props.contextData && props.contextData.length > 0 &&
                <div className="tl-detail-controls">
                    <div className="tl-detail-hc align-items-center ml-auto d-none d-lg-flex">
                        <h5 className="tl-detail-hc-h5">
                            <button
                                className="btn btn-link border-0 detail-hc-button collapsed"
                                type="button"
                                aria-expanded={hcOpen}
                                aria-controls={`TLDetail-${props.groupClusterId}-HC`}
                                onClick={() => { if (hcOpen) { hideHCPanel(); } else { openHCPanel(); } }}
                            >
                                <div className="icon icon-globe-hc"></div>
                                <div className="ml-1">Historical context</div>
                                <div className="detail-hc-button-icon">
                                    <div className="icon jd-icon-expand-from-right"></div>
                                </div>
                            </button>
                        </h5>
                    </div>
                </div>
                }
                <div className="pt-3"
                    ref={contentPaneRef}
                    style={{minHeight: hcph > 0 ? `${hcph}px` : 'auto'}}
                >
                    { /* add 'compressed' class iff HC is open & visible */ }
                    <div className={`accordion tl-detail-big-acc ${hcOpen && props.screenWidth >= 992 ? 'compressed' : ''}`}
                        id={`TLDetailList-${props.groupClusterId}`}
                    >
                    {
                        (props.clusterData.part) ? props.clusterData.part?.map((hit, idx) => {
                            return (
                                <TLEvent
                                    parentAccId={props.groupClusterId}
                                    groupClusterEventId={`${props.groupClusterId}-${idx}`}
                                    showingClusterDetail={props.showingClusterDetail}
                                    activeClusterDetail={props.activeClusterDetail}
                                    defaultPerspTab={0}
                                    activeGroup={props.activeGroup}
                                    activeEventRef={activeEventRef}
                                    setActiveEventRef={setActiveEventRef}
                                    isMobileScreen={props.isMobileScreen}
                                    myIdx={idx}
                                    key={`TLEventLL-${props.groupClusterId}-${idx}`}
                                    eventData={hit ?? {}}
                                    eventOpenIdx={eventOpenIdx}
                                    setEventOpenIdx={setEventOpenIdx}
                                    setExpandedIdx={setExpandedIdx}
                                    nextExpanded={(idx < expandedArr.length-1 && expandedArr[idx+1])}
                                />
                            )
                        }) : ''
                    }
                    </div>
                </div>

                { /* the HC -- historical context ------------------------------------------ */ }
                <div className={((hcOpen) ? 'd-none d-lg-block' : 'd-none') + ' tl-detail-hc-slider'}
                    id={`TLDetail-${props.groupClusterId}-HC`}
                    ref={hcpRef}
                    style={{right: `${hcRight}`, minHeight: `${hcMinHt}`}}
                >
                    <div className="hc-base">
                        <div className="hc-headline">
                            Historical events happening during this same period
                        </div>
                        <div className="accordion" id={`HCAccordion-${props.groupClusterId}`}>
                        {
                        props.contextData.map((hit, idx) => {
                            return (
                        <div key={idx} className="accordion-item">
                            <div className="accordion-header" id={`HCAccordion-heading-${props.groupClusterId}-${idx}`}>
                                <button className="accordion-button collapsed hc-acc-button"
                                    type="button" 
                                    data-toggle="collapse"
                                    data-target={`#HCAccordion-collapse-${props.groupClusterId}-${idx}`}
                                    aria-expanded="false"
                                    aria-controls={`HCAccordion-collapse-${props.groupClusterId}-${idx}`}
                                    onClick={handleHiddenBsCollapseShow}
                                >
                                    <h6 className="hc-acc-hdg">
                                        {hit.date_label ?? "FIXME"}
                                    </h6>
                                    <div className={`hc-acc-text`}>
                                        <div className="hc-acc-p">
                                            <span className="event-title">{hit.primary_name?.label ?? 'FIXME'}</span><br />
                                            {hit.description ?? ''}
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id={`HCAccordion-collapse-${props.groupClusterId}-${idx}`} 
                                className="accordion-collapse collapse" 
                                aria-labelledby={`HCAccordion-heading-${props.groupClusterId}-${idx}`}
                                data-parent={`#HCAccordion-${props.groupClusterId}`}
                            >
                                <div className="hc-accordion accordion-body">
                                    {/* empty because everything we want to show is in the heading */}
                                </div>
                            </div>
                        </div>
                            )})
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default TLEventClusterDetail;