import React, { useState, useRef, useEffect, useContext } from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { useElasticSearch, relationshipsContextToQuery, isLocalUrl } from './util.js';

const ExhibitionsListItem = (props) => {
  return <p className="provenance-list-heading-exhibition">{props.item.label ?? 'No label available'}</p>
}

const ProvenanceTabListItem = (props) => {

  let itemTypes = props.item.item_type.map( (t) => { return t.label } )
  let typeLabels = props.item.item_type.filter( (item) => { return ![ 'Provenance Entry'].includes(item.label) } ).map( (item) => { return item.label } )
  let seeMoreLink = props.item.website ? <a href={props.item.website} target="_blank">(go to website)</a> : ''
  // console.log(itemTypes)
  const sequenceDebugInfo = (item) => { return `[start date: ${ item.item_date?.date_first ? new Date(item.item_date?.date_first * 1000) : 'no date'} end date: ${ item.item_date?.date_last ? new Date(item.item_date?.date_last * 1000) : 'no date'} sequence: ${ item.sequence ? Number(item.sequence) : 'no sequence'}]` }  

  if (  itemTypes.includes("Provenance Entry") ) {
    return <p className="provenance-list-heading">{props.item.description ?? props.item.label}&nbsp;{seeMoreLink}</p>
  } else {
    return <p className="provenance-list-detail"><span className="provenance-detail-category">{ typeLabels.join(', ') }:</span> {props.item.description ?? props.item.label}</p>
  }

}

function identQuery(ident) {
  if (ident) {
    return { query: { ids: { values: [ ident ] } } }  
  } 
  
  return { }
}

const provSort = (item1,item2) => {

  /*
  A simple comparator for sorting. Since dates are handled as epoch seconds we can just compare them directly

  To do the comparison
  - We normalize first and last dates so everything has first / last ranges
  - We return -1 for anything where `item1` is earlier
  - We return 1 for anything where `item1` is later
  - We return -1 for anything that's a Prov entry
  - We return -1 for the low sequence
  - We return 0
  */

  let item1First = item1.item_date?.date_first ?? item1.item_date?.date_last;
  let item1Last = item1.item_date?.date_last ?? item1.item_date?.date_first;
  let item2First = item2.item_date?.date_first ?? item2.item_date?.date_last;
  let item2Last = item2.item_date?.date_last ?? item1.item_date?.date_first;

  let item1Types = item1.item_type.map( (t) => { return t.label } )
  let item2Types = item2.item_type.map( (t) => { return t.label } )

  // If one of the items has a first date earlier, sort it higher
  if ( item1First < item2First ) {
    console.log('item1 date lo')
    return -1
  }

  if ( item1First > item2First ) {
    console.log("item1 date hi")
    return 1
  }

  // If something only one of the items has item_type == Provenance Entry, sort it higher
  if ( item1Types.includes('Provenance Entry') && !item2Types.includes('Provenance Entry') ) {
    console.log("item1 should be lo")

    console.log(item1Types,item2Types)
    return -1
  }

  if ( !item1Types.includes('Provenance Entry') && item2Types.includes('Provenance Entry') ) {
    console.log("item1 should be hi")
    console.log(item1,item2)
    return 1
  }

  // If one of the items has sequence lower, sort it higher
  if ( Number(item1.sequence) < Number(item2.sequence) ) {
    console.log("sequence item1 low")
    console.log(item1,item2)
    return -1
  }

  if ( Number(item1.sequence) > Number(item2.sequence) ) {
    return 1
  }
  
  return 0

}

const ProvenanceTab = (props) => {
  const [ endpoint, setEndpoint ] = useState(props.endpoint);
  const [ ident, setIdent ] = useState(props.ident)
  const [ query, setQuery ] = useState(identQuery(props.ident))

  const results = useElasticSearch(endpoint, query);

  const parseOptions = {
    replace: (domNode) => {
      if (!domNode.attribs) {
        return;
      }
      if (domNode.name === 'a' && !isLocalUrl(domNode.attribs.href)) {
        domNode.attribs.target = '_blank';
        return domNode;
      }
    },
    trim: true
  };

  const provNote = DOMPurify.sanitize( results?.hits?.hits?.length > 0 ? results?.hits?.hits[0]._source?.bibliography?.html ?? '' : '' );
  const provenanceNoteParsed = parse(provNote, parseOptions);

  return <div
    className={`tab-pane clearfix fade ${props.show ? 'show' : ''} ${props.active ? 'active' : ''}`}
    id="provenance"
    role="tabpanel"
    aria-labelledby="provenance-tab"
    aria-expanded={props.active}
    aria-hidden={!props.active}
  >
    <div className="container-fluid">
      <div className="provenance-container">
        { results?.hits?.total?.value > 0 &&
          <div className="subsection-section">
            { results?.hits?.hits[0]._source.provenance?.map( (item,idx) => { return <ProvenanceTabListItem item={item} /> } ) } 
          </div>
        }

        { (results?.hits?.total?.value > 0 && results?.hits?.hits[0]._source.exhibitions?.length > 0) &&
          <div className="subsection-section">
            <h3 className="subsection-section-heading">Exhibitions</h3>
            { results?.hits?.hits[0]._source.exhibitions?.map( (item,idx) => { return <ExhibitionsListItem item={item} /> } ) }
          </div>
        }

        <div className={ results?.hits?.hits.length > 0 && results?.hits?.hits[0]._source?.bibliography?.html ? "subsection-section subsection-notes-section" : "subsection-section subsection-notes-section d-none" }>
          <h3 className="subsection-section-heading">Literature</h3>

          <p className="subsection-note">
          { provenanceNoteParsed }
          </p>
        </div>

      </div>

      { /* debug section: */ }
      { /* <div className="list-group">
        
      </div> */ }

    </div>
  </div>
}

export { ProvenanceTab };