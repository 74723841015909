import React from 'react';

const TypeaheadResultsListItem = (props) => {
    const label = props.result._source?.primary_name?.label;
    const id = props.result._id;
    const pref = 'http://www.collectionbrowse.org';
    const url = ((id.substr(0, pref.length) === pref) ? id.substr(pref.length) : id);
    // console.log('TypeaheadResultsListItem');
    // console.log(props);
    return <li className="list-group-item"><a href={url}>{label}</a></li>
}

const TypeaheadResultsList = (props) => {
    return <ul className="list-group position-absolute typeahead-results-list">
        {
            (props.results.length == 0) 
                ? <li className="list-group-item">No results found</li>
                : props.results.map( (hit, idx) => {
                    return <TypeaheadResultsListItem result={hit} />
                })
        }
    </ul>
}

export default TypeaheadResultsList;