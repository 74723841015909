import React from 'react';

const PerspectiveDocument = (props) => {
  // console.log(props);
  return props.item.representation?.link ? (
    <div className="persplist-doc">
      <a href={props.item.link}>
        <img className="persplist-doc-img"
          src={props.item.representation.link}
          alt="Scan of a page from the document"
        />
      </a>
      <a className="persplist-hdln" href={props.item.link}>{props.item.label}</a>
    </div>
  ) : (
    <div className="persplist-item">
        <div className="persplist-icon">
            <span className={`icon icon-md icon--jd-grey-blue-5 jd-icon-doc-mask`}></span>
        </div>
        <div className="persplist-text">
            <a className="persplist-hdln" href={props.item.link}>{props.item.label}</a>
            <div className="persplist-desc">{/*Subhead goes here--would truncated description work? Occupation?*/}</div>
        </div>
    </div>
  )
}

const PerspectiveListItem = (props) => {
  var iconClass;
  // console.log(props);

  switch(props.item.type) {
    case 'Person':
      iconClass = "jd-icon-users-mask";
      break;
    case 'Location':
      iconClass = "icon-pin-mask";
      break;
    case 'Group':
      iconClass = "jd-icon-org-mask";
      break;
    case 'Artwork':
      iconClass = "jd-icon-frame";
      // console.log(props);
      break;
    case 'Event':
    case 'TimelineEvent':
      iconClass = "jd-icon-events";
      break;
    default:
      iconClass = "jd-icon-users-mask";
      break;
  }

  return (
    <div className="persplist-item">
        <div className="persplist-icon">
            <span className={`icon icon-md icon--jd-grey-blue-5 ${iconClass}`}></span>
        </div>
        <div className="persplist-text">
            <a className="persplist-hdln" href={props.item.link}>{props.item.label}</a>
            <div className="persplist-desc">{/*Subhead goes here--would truncated description work? Occupation?*/}</div>
        </div>
    </div>
  )
}

const PerspectiveList = (props) => {
  return (
      <div className="persplist">
          <div className="d-flex">
              { /* list documents here */ }
              { props.related?.filter(item => item.type === 'Document')?.map( (item,idx) => <PerspectiveDocument key={idx} item={item} /> ) ?? ''}
          </div>
          <div className="persplist-item-container">
              { /* list all other resource types, starting w. locations */ }
              { props.related?.filter(item => item.type === 'Location').map( (item,idx) => <PerspectiveListItem key={idx} item={item} /> ) ?? ''}
              { props.related?.filter(item => item.type === 'Person').map( (item,idx) => <PerspectiveListItem key={idx} item={item} /> ) ?? ''}
              { props.related?.filter(item => item.type === 'Group').map( (item,idx) => <PerspectiveListItem key={idx} item={item} /> ) ?? ''}
              { props.related?.filter(item => item.type === 'Artwork').map( (item,idx) => <PerspectiveListItem key={idx} item={item} /> ) ?? ''}
          </div>
      </div>
  )
}

export default PerspectiveList;