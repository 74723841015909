import React, { useState, useEffect } from 'react';
import { useElasticSearch, timelineQuery, useWindowSize, eventNormalize } from './util.js'
import TLEventGroup from './components/TLEventGroup';
import TLEventClusterDetail from './components/TLEventClusterDetail';

/*
  JDCRP Timeline Component:

  The timeline is composed of:
  - "groups" on the left sidebar
  - "clusters" which are contained in groups and may also become focus objects in the right pane
    - (All clusters in a group [n~=10] displayed on disclose)
  - "events" which are contained in clusters and are viewed on the right
    - (All events in a cluster [n~=10] displayed on disclose)
*/

function groupData(hits) {
  /*
  `hits`: An array of ElasticSearch hit objects (usually from (query_result).hits.hits )
  
  Hits are an unstructured array but have part / part_of rels that form a tree, this reconstructs it:
  - Collects groups (eg things classified "level 1")
  - Collects clusters (eg things classified "level 2")
  - Collects perspectives (eg things classified "level 3 - perspective")
  - Collects historical contextual events (eg things classified "T3-major-events")
  - All events are normalized with `eventNormalize()`--mostly ensuring smart defaults for date labels
  - It then processes the remaining events:
    - Placing variant events under their perspective (these are linked by id at event.part.link )
    - Placing the remaining events under a cluster by its begin / end dates
    - Placing clusters under the groups using begin / end dates  
  
  */
  if (hits.length === 0) {
    return [[],[]]
  }


  // console.log(`Spec data grouping started at ${Date()}`)
  let minDateSecs = hits.reduce( (acc,cur) => { return (cur._source.timespan.date_first < acc) ? cur._source.timespan.date_first : acc } , Infinity )
  let maxDateSecs = hits.reduce( (acc,cur) => { return (cur._source.timespan.date_first > acc) ? cur._source.timespan.date_first : acc } , -Infinity )

  let minDate = new Date(minDateSecs * 1000) // FIXME: Floor this to the nearest 10
  let maxDate = new Date(maxDateSecs * 1000) // FIXME: Ceil this to the nearest 10 

  let minYr = minDate.getFullYear()
  let maxYr = maxDate.getFullYear()

  // First, filter out everything for the hierarchy and the context view
  var groups = hits.filter( item => { return item._source.classified_as?.some( klass => klass.label === "level 1" ) } )
                    .map(eventNormalize)
  var clusters = hits.filter( (item,idx) => { return item._source.classified_as?.some( klass => klass.label === "level 2" ) } )
                    .map(eventNormalize)
  var perspectives = hits.filter( (item,idx) => { return item._source.classified_as?.some( klass => klass.label === "level 3 - perspective" ) } )
                    .map(eventNormalize)
  var contexts = hits.filter( (item,idx) => { return item._source.classified_as?.some( klass => klass.label === "T3-major-events" ) } )
                    .map(eventNormalize)
  // Anything else is just an event
  let events = hits.filter( item => !groups.some(grp => grp._id === item._id ) && 
                                    !clusters.some(clst => clst._id === item._id ) && 
                                    !perspectives.some(psp => psp._id === item._id ) && 
                                    !contexts.some(ctx => ctx._id === item._id ) )
                    .map(eventNormalize)
  // console.log(events)

  var variants = []
  var seen = []
  for (let perspective of perspectives) {
    let perspectiveEvents = events.filter( ev => perspective.part.some( p => p.link == ev._id ) )
    variants = variants.concat(perspectiveEvents)
    seen = seen.concat(perspectiveEvents)
    perspective.part = perspectiveEvents
  }

  // console.log(variants)
  for ( let cluster of clusters ) {
    let clusterEvents = events.filter( ev => cluster.date_first <= ev.date_first && ev.date_last <= cluster.date_last && !variants.includes(ev) )
    let perspEvents = perspectives.filter( ev => cluster.part.some( clstPart => clstPart.link == ev._id ) )
    seen = seen.concat(clusterEvents)
    cluster.part = clusterEvents.concat(perspEvents)
  }

  // Iterate groups
  for ( let group of groups ) {
    let groupedClusters = clusters.filter( cls => group.date_first <= cls.date_first && cls.date_last <= group.date_last )
    group.part = groupedClusters
  }
  // console.log(seen)
  // console.log("Leftover events:")
  // console.log(events.filter( ev => !seen.some( sn => sn._id == ev._id )  ))

  // ... more brutal profiling:
  // console.log(`Spec data grouping finished at ${Date()}`)

  return [contexts,groups]
}

const TimelineTab = (props) => {

  /* ElasticSearch communication + model state */
  const [ endpoint, setEndpoint ] = useState(props.endpoint)
  const [ query, setQuery ] = useState(timelineQuery())

  /* Display state */

  const [ activeGroup, setActiveGroup ] = useState(''); 
  const [ activeGroupRef, setActiveGroupRef ] = useState(null);
  const [ groupWasSelected, setGroupWasSelected ] = useState(false);

  const [ activeClusterDetail, setActiveClusterDetail ] = useState('');
  const [ showingClusterDetail, setShowingClusterDetail ] = useState('');

  const results = useElasticSearch(endpoint,query)

  const [eventContexts,eventGroups] = groupData(results?.hits?.hits ?? [])

  // console.log('eventGroups=');
  // console.log(eventGroups);

/*  
FIXME timeline-with-data was:
const selectGroup = (group, clusterInGroup) => {
    console.log('selectGroup: g='+group+'; c='+clusterInGroup+'; active group = '+activeGroup);
    setActiveGroup(group);
*/


  const selectGroup = (group, clusterInGroup, ref) => {
    setGroupWasSelected(true);
    const newActiveGroup = (group === activeGroup) ? '' : group;
    // console.log('selectGroup: group='+group+'; clusterInGroup='+clusterInGroup+'; active group = '+activeGroup+'; new active group = '+newActiveGroup);
    setActiveGroup(newActiveGroup);
    if (ref && newActiveGroup !== '') {
      setActiveGroupRef(ref);
    }

    if (clusterInGroup >= 0) {
      setSelectedClusterDetail((newActiveGroup === '') ? '' : `${group}-${clusterInGroup}`);
    } else {
      setSelectedClusterDetail('');
    }

  }

  const setSelectedClusterDetail = (newId) => {
    // console.log('setting SelectedClusterDetail: newId='+newId);

    // need to distinguish show & active for purposes of fade effect
    setShowingClusterDetail(null);
    // delay for purpose of fade effect on tab content
    setTimeout(() => {
      setActiveClusterDetail(newId);

      // if ( activeGroup >= 0 && newId >= 0 && loadData) {
      //   let data = eventGroups[Number(activeGroup)].part[Number(newId)]

      // }

      setTimeout(() => {
        setShowingClusterDetail(newId);
      }, 15);
    }, 150);   // this should match the transition time on .fade
  }

  const wsize = useWindowSize();
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const isMobileScreenWidth = (w) => (w <= 767);

  useEffect(() => {
    console.log(`wsize useEffect fired with ${wsize.width}`);
    setIsMobileScreen(isMobileScreenWidth(wsize.width));
  }, [wsize]);

  // console.log(`showingClusterDetail is ${showingClusterDetail} while activeClusterDetail is ${activeClusterDetail} and mobile is ${isMobileScreen}`)
  return (
        <div
          className={`tab-pane clearfix fade ${props.show ? 'show' : ''} ${props.active ? 'active' : ''}`}
          id="timeline"
          role="tabpanel"
          aria-labelledby="timeline-tab"
          aria-expanded={props.active}
          aria-hidden={!props.active}
        >
          <div className="container-fluid tl-container">
            <div className="tl-main-layout">
              <div className={`tl-mainlist-col ${isMobileScreen ? 'ismobile' : ''}`}>

                { /* left-side column of EventGroups */ }
                <div className="accordion tl-mainlist-acc" id="TLMainList">
                {
                    eventGroups.map((hit, idx) => {
                        return <TLEventGroup
                          key={idx}
                          myIdx={idx}
                          activeGroup={activeGroup}
                          activeGroupRef={activeGroupRef}
                          selectGroup={selectGroup}
                          groupWasSelected={groupWasSelected}
                          showingClusterDetail={showingClusterDetail}
                          activeClusterDetail={activeClusterDetail}
                          setSelectedClusterDetail={setSelectedClusterDetail}
                          isMobileScreen={isMobileScreen}
                          groupData={hit}
                        />
                    })
                }
                </div>

              </div>
              <div className="tl-event-detail-canvas d-none d-md-flex">

              { /* right-side column of EventClusterDetail panels */ }
                <div className={`tl-event-detail-placeholder tab-pane fade ${showingClusterDetail === '' ? 'show' : ''} ${activeClusterDetail === '' ? 'active' : ''}`}
                  role="tabpanel"
                  aria-expanded={activeClusterDetail === ''}
                  aria-hidden={activeClusterDetail !== ''}
                >
                  Click on a time period on the left to explore events during that period
                </div>
                { /* blank one for transitions: */ }
                <div className={`tl-event-detail-placeholder tab-pane fade ${showingClusterDetail === 'blank' ? 'show' : ''} ${activeClusterDetail === 'blank' ? 'active' : ''}`}
                  role="tabpanel"
                  aria-expanded={activeClusterDetail === 'blank'}
                  aria-hidden={activeClusterDetail !== 'blank'}
                ></div>
                { 
                    (!isMobileScreen) ? ( 
                      eventGroups.map( (group,groupIdx) => {
                        return group.part.map( (cluster,clusterIdx) => {
                          let ident = `${groupIdx}-${clusterIdx}`
                          return <TLEventClusterDetail
                                  groupClusterId={ident}
                                  showingClusterDetail={showingClusterDetail}
                                  activeClusterDetail={activeClusterDetail}
                                  activeGroup={activeGroup}
                                  key={ident}
                                  isMobileScreen={isMobileScreen}
                                  screenWidth={wsize.width}
                                  clusterData={cluster}
                                  contextData={eventContexts.filter( ctx => cluster.date_first <= ctx.date_first && ctx.date_last <= cluster.date_last  )}
                              />
                        })
                      }) ) : '' 
                }
              </div>
            </div>
          </div>
        </div>
    )
}

export { TimelineTab };