import React, { useState, useEffect } from 'react';
import MultiCarouselR from './multiCarousel';
import { OpenSeaDragonViewer } from './osdViewer.js';

const ImageViewerItem = (props) => {
  // console.log('idx='+props.idx+'; selDataIdx='+props.selDataIdx);
  // console.log(props.data);
  const [ selDataIdx, setSelDataIdx ] = useState(props.selDataIdx);
  useEffect(() => {
    setSelDataIdx(props.selDataIdx);
  }, [props.selDataIdx]);

  const itemClickHandler = (event) => {
    // console.log('item click');
    props.itemClickAction(event, props.data, props.idx);
    setSelDataIdx(props.idx);
    props.setSelFunc(event);
  }
  
  const getFreeHeightThumb = (thumb) => {
    const i = thumb.indexOf('&h=');
    return thumb.substring(0, i);
  }

  return (
    <div className={`item ${props.itemClass}`} style={{width: props.itemWidth}}>
      <div className={`grid-viewer-container ${props.idx === selDataIdx ? 'selected' : ''}`}>
        <a className=""
          href="javascript:;"
          onClick={itemClickHandler} 
          onFocus={props.focusfunc}
          aria-current={props.idx === selDataIdx ? 'true' : null}
        >
          <div className="grid-image-container-slider grid-viewslider">
            <figure>
              <img src={props.data.tileSource.url + '?nf_resize=fit&w=256'}
                alt={props.data.alt ?? `Image ${props.idx + 1}`}
                style={{width: props.imgWidth, height: 'auto', maxHeight: 2 * props.imgWidth}}
              />
            </figure>
          </div>
        </a>
      </div>
    </div>
  )
}

function normalizeTileSource(image) {
  return { 
          thumb: image.link,
          label: image.label,
          required_label: image.required_label,
          tileSource: 
            {
              type: 'image',
              url: image.service,
              crossOriginPolicy: 'Anonymous',
              buildPyramid: true
            }
          }
} 

const ImageViewer = (props) => {

  if (!props.images || props.images.length === 0) return null;

  // console.log(props.images);

  const displaySlots = 5; // should be an odd number
  const initEmptySlots = Math.max(0, Math.floor((displaySlots - props.images.length) / 2));
  const initialDisplayIdx = Math.floor(displaySlots / 2) - initEmptySlots;

  const [ itemWidth, setItemWidth ] = useState(110);
  const [ imgWidth, setImgWidth ] = useState(76);
  // console.log('display idx=' + initialDisplayIdx);

  const [image, setImage] = useState( props.images.length > 0 ? props.images[0] : '');

  const openImageInViewer = (e, data, idx) => {
    // console.log('openImageViewer: idx=' + idx);
    // console.log(data);
    setImage(data);
    $('.openseadragon-canvas').trigger('focus');
  }

  // image.label is escaped HTML...
  const caption = new DOMParser().parseFromString(image.label, "text/html");
  const reqStmt = new DOMParser().parseFromString(image.required_label, "text/html");

  return (
    <div className="row mx-0">
      <div className="col-12 px-0">
        <OpenSeaDragonViewer image={image} />
        <div className="entity-image-viewer-caption pt-2">{caption.documentElement.textContent}</div>
        <div className="entity-image-viewer-caption pt-2">{reqStmt.documentElement.textContent}</div>
        <MultiCarouselR
          itemWidth={itemWidth}
          displaySlots={displaySlots}
          alignMode={'center'}
          alwaysShowButtons={false}
          maxWidth={614}
          variableWidth={true}
          imgWidth={imgWidth}
          buttonClass={'sliding-button'}
          selectedIdx={0}
        >
        {
          props.images.map((hit, idx) => {
              return <ImageViewerItem
                itemClickAction={openImageInViewer}
                itemClass={'viewer-slider'}
                data={hit}
              />
          })
        }
        </MultiCarouselR>
      </div>
    </div>
  )
}

export { ImageViewer, normalizeTileSource }