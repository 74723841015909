import React, { useState, useRef, useEffect } from 'react';
// import { useElasticSearch, relationshipsContextToQuery } from './util.js';
import { EntityTabNav } from './entityTabNav.js';
import { RelationshipsTab } from './relationshipsTab.js';
import { DocumentsTab } from './documentsTab.js';
import { ArtworksTab } from './artworksTab.js';
import { EventsTab } from './eventsTab.js';
import { ProvenanceTab } from './provenanceTab.js';
import { InventoriesTab } from './inventoriesTab.js';
import { EssaysTab } from './essaysTab.js';
import { CollectionsTab } from './collectionsTab.js';
// import { AboutTab } from './aboutTab.js';
import { TimelineTab } from './timelineTab.js';
import { useWindowSize } from './util.js';

const getActiveDates = (data) => {
  if (data.active === undefined) {
    return null
  }

  if (data.active.date_label) { return data.active.date_label }
  else if (data.active.date_begin || data.active.date_end) { return `${data.active.date_begin ?? ''}-${data.active.date_end ?? ''}` }

  return null
}

const QVFigure = (props) => {
  switch (props.data.display_type) {
    case "Person":
      return (
        <figure className="qv-person-img-placeholder">
          <div className="icon-person-img icon-60x60">
            <span className="icon icon--white jd-icon-human-qv"></span>
          </div>
          <figcaption className="transbg"><span className="sr-only">{props.data.display_type}</span></figcaption>
        </figure>
      )
      break;
    case "Group":
      return (
        <figure className="qv-person-img-placeholder">
          <div className="icon-person-img icon-60x60">
            <span className="icon icon--white icon-xl jd-icon-org-mask"></span>
          </div>
          <figcaption className="transbg"><span className="sr-only">{props.data.display_type}</span></figcaption>
        </figure>
      )
      break;
    case "Event":
    case "TimelineEvent":
      return (
        <figure className="qv-person-img-placeholder">
          <div className="icon-person-img icon-60x60">
            <span className="icon icon--white icon-xl jd-icon-events"></span>
          </div>
          <figcaption className="transbg"><span className="sr-only">{props.data.display_type}</span></figcaption>
        </figure>
      )
      break;
    case "Artwork":
      return (
        <figure className="qv-person-img-placeholder">
          <div className="icon-person-img icon-60x60">
            <span className="icon icon--white icon-xl jd-icon-frame"></span>
          </div>
          <figcaption className="transbg"><span className="sr-only">{props.data.display_type}</span></figcaption>
        </figure>
      )
      break;
    case "Document":
      return (
        <figure className="qv-person-img-placeholder">
          <div className="icon-person-img icon-60x60">
            <span className="icon icon--white icon-xl jd-icon-file-mask"></span>
          </div>
          <figcaption className="transbg"><span className="sr-only">{props.data.display_type}</span></figcaption>
        </figure>
      )
      break;
    case "Location":
      // const classification = (props.data.classified_as && props.data.classified_as.length > 0) ? props.data.classified_as[0].label : '';
      const subtype = (props.data.classified_as && props.data.classified_as.length > 0
        && props.data.classified_as.map(d => d.label.toLowerCase()).includes('address')
        ? 'bldgs'
        : 'map');
      if (subtype === 'bldgs') {
        return (
          <figure className="qv-person-img-placeholder">
            <div className="icon-person-img icon-60x60">
              <span className="icon icon--white icon-xl jd-icon-bldgs-mask"></span>
            </div>
            <figcaption className="transbg"><span className="sr-only">{props.data.display_type}</span></figcaption>
          </figure>
        )
      } else {
        return (
          <figure className="qv-person-img-placeholder">
            <div className="icon-map-img icon-60x60">
              <span className="icon icon-60x60 icon-boxed jd-icon-city"></span>
            </div>
            <figcaption className="transbg"><span className="sr-only">{props.data.display_type}</span></figcaption>
          </figure>
        )
      }
      break;
    default:
      return (
        <figure className="qv-person-img-placeholder">
          <div className="icon-person-img icon-60x60">
            <span className="icon icon--white jd-icon-human-qv"></span>
          </div>
          <figcaption className="transbg"><span className="sr-only">{props.data.display_type}</span></figcaption>
        </figure>
      )
      break;
  }
}

const TabViewController = (props) => {
  /* FIXME:
  - Move ES communication here (pages, change handlers, etc)
  */

    // console.log(JSON.stringify(props));
    const qvp = useRef(null);
    const [ qvOpen, setQvOpen ] = useState(false);
    const [ qvData, setQvData ] = useState({});
    const [ qvFullHeight, setQvFullHeight ] = useState(false);
    const [ qvOnscreen, setQvOnscreen ] = useState(false);
    const [ qvTop, setQvTop ] = useState('0');
    const [ currentQVItem, setCurrentQVItem ] = useState(null);
    const [ qvStoredActiveElement, setQvStoredActiveElement ] = useState(null);
    const tabContentRef = useRef(null);
    const qvCloseRef = useRef(null);
    const shimRef = useRef(null);
    const shimNativeHeight = 500;
    const [ shimHeight, setShimHeight ] = useState(shimNativeHeight);
    const [ shimHidden, setShimHidden ] = useState(true);
    const [ blockerTransparent, setBlockerTransparent ] = useState(true);

    const getTabData = (pgType) => {
      let tabData;
      switch (pgType) {
      case 'Collection':
        tabData = [{
            'name': 'timeline',
            'label': 'Timeline'
          }, {
            'name': 'artworks',
            'label': 'Artworks'
          }/*, {
            'name': 'essays',
            'label': 'Essays'
          }*/];
        break;
      case 'Artwork':
        tabData = [{
            'name': 'provenance',
            'label': 'Provenance'
          }, {
            'name': 'inventories',
            'label': 'Inventories'
          }, {
            'name': 'relationships',
            'label': 'Relationships'
          }];
        break;
      case 'Artist':
        tabData = [{
            'name': 'artworks',
            'label': 'Artworks'
          }, {
            'name': 'essays',
            'label': 'Essays'
          }, {
            'name': 'relationships',
            'label': 'Relationships'
          }];
        break;
      case 'Group':
        tabData = [{
            'name': 'relationships',
            'label': 'Relationships'
          }, {
            'name': 'essays',
            'label': 'Essays'
          }];
        break;
      case 'Location':
        tabData = [{
            'name': 'relationships',
            'label': 'Relationships'
          }, {
            'name': 'essays',
            'label': 'Essays'
          }];
        break;
      case 'Event':
      case 'TimelineEvent':
        tabData = [{
            'name': 'relationships',
            'label': 'Relationships'
          }];
        break;
      case 'Document':
        tabData = [{
            'name': 'relationships',
            'label': 'Relationships'
          }];
        break;
      case 'Essays':
        tabData = [{
            'name': 'relationships',
            'label': 'Relationships'
          }];
        break;
      case 'Person':
      default:
        tabData = [{
            'name': 'relationships',
            'label': 'Relationships'
          }, {
            'name': 'essays',
            'label': 'Essays'
          }];
        break;
      }
      return tabData;
    }
    const tabData = getTabData(props.type);
    const tabDataNames = tabData.map(d => d.name);
    const initialTab = tabData.length > 0 ? tabData[0].name : null;
    const [ activeTab, setActiveTab ] = useState(initialTab);
    const [ showTab, setShowTab ] = useState(initialTab);

    let focusables = null;

    const goToQVRecord = () => {
      var url = new URL(window.location.href)
      const qvTargetPath = qvData._id.replace('http://www.collectionbrowse.org','')
      url.pathname = qvTargetPath
      let href = url.toString()
      window.location =  href
    }

    const qvTabListener = (e) => {
      if (!focusables) { return; }
      //console.log(focusables);
      const KEYCODE_TAB = 9;
      const firstFocusableEl = focusables[0];  
      const lastFocusableEl = focusables[focusables.length - 1];
      const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
      if (!isTabPressed) { return; }
      const qvIsOpen = !qvp.current.classList.contains('d-none');
      if (!qvIsOpen) {
        //console.log('tab but QV is not open');
        return;
      }

      if (e.shiftKey) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          //console.log('-- shift-tab -- active is el #1');
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          //console.log('-- tab -- active is el #2');
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
      // if focus not within the QV, grab it
      if (!document.activeElement || document.activeElement.classList.contains('rel-item-link1')) {
        firstFocusableEl.focus();
        e.preventDefault();
      }
    }

    useEffect(() => {
      // when we've slid the QV offscreen, then we need to do some cleanup
      if (qvOpen && !qvOnscreen) {
        // delay hiding because first we'll be sliding
        setTimeout(() => {
          document.removeEventListener('keydown', qvTabListener);
          qvStoredActiveElement?.focus();
          // itemMouseUpHandler in relationshipsTab sets this when clicking on the item, undo it here in case keyboard is used:
          qvStoredActiveElement?.classList.toggle('focus-outline-none', false);
          setShimHeight(shimNativeHeight);

          setQvOpen(false);
          setQvFullHeight(false);
          currentQVItem?.classList.toggle('rel-item-qv-active', false);
          setCurrentQVItem(null);
          setShimHidden(true);
        }, 250);
      }
    }, [qvOnscreen]);

    useEffect(() => {
      // after the QV is opened, set shim stuff, set up focus stuff and slide the QV panel in from right
      if (qvOpen) {
        // start the shim growing in height, slide in the QV panel
        setTimeout(() => {
          const win = tabContentRef.current.ownerDocument.defaultView;
          const tabContentTop = tabContentRef.current.getBoundingClientRect().top + win.pageYOffset;
          const footerTop = document.getElementById('theFooter').getBoundingClientRect().top + win.pageYOffset;
          const qvph = qvp.current.offsetHeight;  // expensive calculation?
          const shimHt = Math.max(Math.max(shimNativeHeight, (footerTop - tabContentTop - 30)), (qvph + qvTop));
          setShimHeight(shimHt);

          if (tabContentTop + qvTop + qvph < footerTop) {
            setQvFullHeight(true);
          }
          // slide in from right:
          setQvOnscreen(true);
          // add tab-listener to qvp, so the focus is 'trapped' inside it
          if (focusables === null) {
            focusables = qvp.current.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
          }
          document.addEventListener('keydown', qvTabListener);
        }, 20);
      }
    }, [qvOpen]);

    const rememberActiveElement = () => {
      // remember where the focus was, so we can go back to it when the QV is closed:
      // console.log('active element = ');
      // console.log(document.activeElement);
      setQvStoredActiveElement(document.activeElement);
    }

    const openQVPanel1 = (e, data) => {
      setQvData(data);
      e.currentTarget.parentNode.classList.toggle('rel-item-qv-active', true);
      setCurrentQVItem(e.currentTarget.parentNode);
      // set the QVP's top coordinate
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const win = tabContentRef.current.ownerDocument.defaultView;
      const tabContentTop = tabContentRef.current.getBoundingClientRect().top + win.pageYOffset;
      // const footerTop = document.getElementById('theFooter').getBoundingClientRect().top + win.pageYOffset;
      const fixedTopStuffHeight = 130;
      const topStuffHt = $('.sticky-potential').css('display') === 'none' ? fixedTopStuffHeight : $('.sticky-potential').position().top + $('.sticky-potential').innerHeight();
      const qvt = Math.max(0, scrollTop + topStuffHt - tabContentTop);
      // console.log('scrollTop: ' + scrollTop + '; tabContentTop: ' + tabContentTop + '; footerTop: ' + footerTop + '; qv top = ' + qvt + '; topStuffHt = ' + topStuffHt);
      setQvTop(qvt);
      setQvFullHeight(false);
      // show the shim (will adjust height later):
      setShimHeight(shimNativeHeight);
      setShimHidden(false);
      setQvOpen(true);
      // increase the blocker's opacity:
      setBlockerTransparent(false);
      // useEffect above will handle the rest
    }

    // close the quick-view panel
    const hideQV1 = () => {
      // console.log('hideQV1');
      setQvOnscreen(false);
      // decrease the blocker's opacity:
      setBlockerTransparent(true);
      // useEffect above will handle the rest
    }

    // for tab sliding behavior when needed: =====================================

    // 2ND IMPLEMENTATION OF THE REACTIFIED 'MULTICAROUSEL' - 1st was in the Relationships tab
    const slv = useRef();
    // slideX: can range from 0 down to (slvw - total width)
    const [ slideX, setSlideX ] = useState(0);
    const [ tabsCurrentWidth, setTabsCurrentWidth ] = useState(-1);
    // slvw is the width of the slider-viewport -- the element (ref = slv) is set to width 100%
    const [ slvw, setSlvw ] = useState(500);
    const tabSlideRef = useRef(null);
    const tablistRef = useRef(null);
    const [ tabsOutLeft, setTabsOutLeft ] = useState(0);

    const moveSlider = (s) => {
      // move by how much? A: by the width of a tab
      // which tab? A: the leftmost visible one if we're sliding left; otherwise, the rightmost of the ones hidden to the left
      // or multiple tab widths if abs(s) > 1
      if (tablistRef?.current && s !== 0) {
        const tabEls = tablistRef.current.querySelectorAll('li');
        if (tabEls.length > 0) {
          const tabElsArray = Array.from(tabEls);
          const tabElsWidths = tabElsArray.map(val => val.offsetWidth);
          // const tabWidth = (s<0 ? tabElsWidths[tabsOutLeft] : tabElsWidths[tabsOutLeft-1]);
          const deltaX = s<0 ? 
            // moving left; get sum of first (0-s) elements starting at the leftmost visible ([tabsOutLeft])
            tabElsWidths.slice(tabsOutLeft, tabsOutLeft-s).reduce((acc, val) => { return acc + val; }, 0)
            : // else moving right; get sum of the s elements closest to the visible one but hidden to the left
            -(tabElsWidths.slice(tabsOutLeft-s, tabsOutLeft).reduce((acc, val) => { return acc + val; }, 0));

          // console.log('tab widths: ' + tabElsWidths);
          // console.log('s: ' + s + '; deltaX: ' + deltaX + '; tabs out left: ' + tabsOutLeft + '; slideX: ' + slideX);
          setTabsOutLeft(Math.max(0, tabsOutLeft - s));
          // const newSlideX = Math.min(0, slideX + s * tabWidth);
          const newSlideX = Math.min(0, slideX - deltaX);
          setSlideX(newSlideX);
        }
      }
    }

    const sliderButtonAreaWidth = 25;
    const setSliderWidths = () => {
      // to get the width the inner slider should be, compute the sum of the widths of all the tab LI elements:
      if (slv?.current) {
        const newW = slv.current.offsetWidth;
        // console.log('tabs on mount: set slvw to ' + newW);
        setSlvw(newW);
        if (tablistRef?.current) {
          const tabEls = tablistRef.current.querySelectorAll('li');
          if (tabEls.length > 0) {
            const tabElsArray = Array.from(tabEls);
            const liWidthSum = tabElsArray.reduce((acc, val, idx) => { return acc + (idx===0 ? val.offsetLeft : 0) + val.offsetWidth; }, 0);
            // console.log('wSum = ' + liWidthSum);
            setTabsCurrentWidth(liWidthSum);
          }
        }
      }
    }

    const slidingItemFocus = (idx) => {
      // what index has 2nd displayed position?
      const place2Index = tabsOutLeft + 1;
      // console.log('item focus: idx=' + idx + '; p2I=' + place2Index);
      // if idx < p2I, need to move right, unless slideX is 0 or more
      if (idx < place2Index) {
        if (slideX === 0) {
          setTabsOutLeft(0);
        } else {
          const steps = place2Index - idx;
          // console.log('sliding right by ' + steps);
          moveSlider(steps);
        }
      } else {
        // if idx > p2I, need to move left
        if (idx > place2Index) {
          if (slideX > slvw-tabsCurrentWidth) {
            let steps = idx - place2Index;
            // console.log('sliding left by ' + steps + '; slideX = ' + slideX + '; slvw-tabsCurrentWidth = ' + (slvw-tabsCurrentWidth));
            const tabEls = tablistRef.current.querySelectorAll('li');
            if (tabEls.length > 0) {
              const tabElsArray = Array.from(tabEls);
              const tabElsWidths = tabElsArray.map(val => val.offsetWidth);
              // don't go more steps than we need to
              while (steps > 0 && -(tabElsWidths.slice(Math.max(0, tabsOutLeft-steps), tabsOutLeft).reduce((acc, val) => { return acc + val; }, 0)) < slvw-tabsCurrentWidth) {
                steps--;
                // console.log('decremented steps to ' + steps + '; tabs left = ' + tabsOutLeft);
              }
              moveSlider(-steps);
            }
          }
        }
      }
    }

    useEffect(() => {
      setSliderWidths();
    }, []); // on mount

    // == end of main slider stuff ====================================================

    // keep track of window size:
    const wsize = useWindowSize();
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const isMobileScreenWidth = (w) => (w < 768);

    useEffect(() => {
      // console.log('w width: '+wsize.width);
      // console.log(props.type);
      setIsMobileScreen(isMobileScreenWidth(wsize.width));

      // more slider stuff:
      setSliderWidths();
    }, [wsize]);

    return <div className="container-fluid px-0">
      <div className="row mx-0">
        <div className="col-md-12 p-0">

          <div className="sliding-top"
            ref={tabSlideRef}
          >
            <button
                type="button"
                className={`btn p-0 btn-link sliding-button sliding-button-left sliding-button-base ${(slideX < 0) ? '' : 'hid'}`}
                onClick={(e) => moveSlider(1)}
            >
                <div className="icon icon-carat-left icon--jd-teal pr-1">
                    <span className="sr-only">Previous</span>
                </div>
            </button>
            <div className="sliding-view"
              ref={slv}
            >
              <div className="sliding-inner" style={{width: `${tabsCurrentWidth === -1 ? '100%' : ((tabsCurrentWidth + 50)/* no harm in padding this width, to make sure no float wrapping happens */+'px')}`, transform: `translateX(${slideX}px)`, transitionDuration: '0.5s'}}>
                <ul className={`nav nav-tabs nav-outset mx-auto my-0 entity-main-tabs ${tabsCurrentWidth >= 0 && wsize.width < 992 ? 'smaller' : ''} ${isMobileScreen ? 'ismobile' : ''}`}
                  id="theMainTabs"
                  ref={tablistRef}
                  role="tablist"
                >
                {
                  tabData.map((hit, idx) => {
                      return <EntityTabNav
                        nameRef={hit.name}
                        nameLabel={hit.label}
                        active={(activeTab === hit.name)}
                        setActive={setActiveTab}
                        show={(showTab === hit.name)}
                        setShowTab={setShowTab}
                        myIdx={idx}
                        focusfunc={slidingItemFocus}
                      />
                  })
                }
                </ul>
              </div>
            </div>
            <button
                type="button"
                className={`btn p-0 btn-link sliding-button sliding-button-right sliding-button-base ${(tabsCurrentWidth === -1 || slideX + tabsCurrentWidth <= slvw + sliderButtonAreaWidth /* add the button width so we don't show the button before we really need to */) ? 'hid' : ''}`}
                onClick={(e) => moveSlider(-1)}
            >
                <div className="icon icon-carat-right icon--jd-teal pr-1">
                    <span className="sr-only">Next</span>
                </div>
            </button>
          </div>

        </div>
      </div>

      <div className="row bg-white mx-0 pt-3"> {/* make sure there's no bottom padding here, for QV panel height */}
        <div className="tab-content" ref={tabContentRef} id="personTabContent">
          { tabDataNames.includes('relationships') &&
          <RelationshipsTab {...props} shimRef={shimRef} shimHeight={shimHeight} shimHidden={shimHidden} itemClickAction={openQVPanel1} storeActive={rememberActiveElement} active={(activeTab === 'relationships')} show={(showTab === 'relationships')} />
          }
          { tabDataNames.includes('documents') &&
          <DocumentsTab {...props} active={(activeTab === 'documents')} show={(showTab === 'documents')} />
          }
          { tabDataNames.includes('artworks') &&
          <ArtworksTab {...props} active={(activeTab === 'artworks')} show={(showTab === 'artworks')} />
          }
          { tabDataNames.includes('events') &&
          <EventsTab {...props} active={(activeTab === 'events')} show={(showTab === 'events')} />
          }
          { tabDataNames.includes('provenance') &&
          <ProvenanceTab {...props} active={(activeTab === 'provenance')} show={(showTab === 'provenance')} />
          }
          { tabDataNames.includes('inventories') &&
          <InventoriesTab {...props} active={(activeTab === 'inventories')} show={(showTab === 'inventories')} />
          }
          { tabDataNames.includes('essays') &&
          <EssaysTab {...props} active={(activeTab === 'essays')} show={(showTab === 'essays')} />
          }
          { tabDataNames.includes('collections') &&
          <CollectionsTab {...props} active={(activeTab === 'collections')} show={(showTab === 'collections')} />
          }
          { tabDataNames.includes('timeline') &&
          <TimelineTab {...props} active={(activeTab === 'timeline')} show={(showTab === 'timeline')} />
          }

          {/* QUICK VIEW (QV) PANEL: absolutely positioned panel that slides in from right */}
          <div className={`${qvOpen ? '' : 'd-none'} ${qvOnscreen ? 'qv-onscreen' : ''} ${qvFullHeight ? 'qv-full-height' : ''}`} id="qvPanel" ref={qvp} style={{top: `${qvTop}px`}}>
            <div className={`blocker ${blockerTransparent ? 'blocker-transparent' : ''}`} onClick={hideQV1}></div>
            <div className="qv-base">
              <button
                className="btn border-0 qv-close-x"
                onClick={hideQV1}
                ref={qvCloseRef}
              >
                <span className="icon jd-icon-quickview-close"></span><span className="sr-only">Close this summary panel</span>
              </button>
              <div className="qv-base-pad">

                <div className="row mx-0 mb-2 mt-4"> {/* use top margin only when no image above */}
                  <div className="col col-auto p-1">
                    <QVFigure data={qvData} />
                  </div>
                  <div className="col pl-2">
                    <h4 className="qv-person-name">
                      { qvData.primary_name?.label ?? 'No name' }
                    </h4>
                    <div className="qv-person-role">
                      { qvData.occupation?.length > 0 ? qvData.occupation[0]?.label ?? '' : '' }
                    </div>
                  </div>
                </div>

                <div className="px-2">

                  <dl className={ getActiveDates(qvData) !== null ? "person-page-qv-dl" : "person-page-qv-dl d-none" }>
                    <dt className="person-page-info-qv-heading">Active years</dt>
                    <dd className="person-page-info">
                      { getActiveDates(qvData) }
                    </dd>
                  </dl>

                  <p>{ qvData && qvData.description ? qvData.description.text.substring(0,260) : "No description available"  }</p>

                  <button type="button" className="btn btn-primary" onClick={() => goToQVRecord()}>
                    Full Record
                    <span class="icon button-icon icon-arrow-rt0 icon--white"></span>
                  </button>

                  <hr />

                  <h5 className="qv-person-rel pt-2 pb-3">
                    Relationship to {
                      props.entityname || '[name goes here]'
                    }</h5>

                  <div className="row mb-3 mr-0">
                    <div className="col-4">
                      <div className="qv-relations-card">
                        <div className="qv-relations-card-top">
                          Events<span className="sr-only">:</span> 
                          <div className="qv-rel-card-diamond">
                            <span className="icon icon-gray-diamond"></span>
                          </div>
                        </div>
                        <div className="qv-relations-card-bottom">
                          <div className="relations-qv-big-count">{qvData.related?.filter( item => item.type === "TimelineEvent" ).length ?? 0}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="qv-relations-card">
                        <div className="qv-relations-card-top">
                          Artworks<span className="sr-only">:</span> 
                          <div className="qv-rel-card-diamond">
                            <span className="icon icon-gray-diamond"></span>
                          </div>
                        </div>
                        <div className="qv-relations-card-bottom">
                          <div className="relations-qv-big-count">{qvData.related?.filter( item => item.type === "Artwork" ).length ?? 0}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="qv-relations-card">
                        <div className="qv-relations-card-top">
                          Documents<span className="sr-only">:</span> 
                          <div className="qv-rel-card-diamond">
                            <span className="icon icon-gray-diamond"></span>
                          </div>
                        </div>
                        <div className="qv-relations-card-bottom">
                          <div className="relations-qv-big-count">{qvData.related?.filter( item => item.type === "Document" ).length ?? 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 mr-0">
                    <div className="col-4">
                      <div className="qv-relations-card">
                        <div className="qv-relations-card-top">
                          People<span className="sr-only">:</span> 
                          <div className="qv-rel-card-diamond">
                            <span className="icon icon-gray-diamond"></span>
                          </div>
                        </div>
                        <div className="qv-relations-card-bottom">
                          <div className="relations-qv-big-count">{qvData.related?.filter( item => item.type === "Person" ).length ?? 0}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="qv-relations-card">
                        <div className="qv-relations-card-top">
                          Organizations<span className="sr-only">:</span> 
                          <div className="qv-rel-card-diamond">
                            <span className="icon icon-gray-diamond"></span>
                          </div>
                        </div>
                        <div className="qv-relations-card-bottom">
                          <div className="relations-qv-big-count">{qvData.related?.filter( item => item.type === "Organization" ).length ?? 0}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="qv-relations-card">
                        <div className="qv-relations-card-top">
                          Locations<span className="sr-only">:</span> 
                          <div className="qv-rel-card-diamond">
                            <span className="icon icon-gray-diamond"></span>
                          </div>
                        </div>
                        <div className="qv-relations-card-bottom">
                          <div className="relations-qv-big-count">{qvData.related?.filter( item => item.type === "Location" ).length ?? 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
}

export { TabViewController };