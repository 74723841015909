import React, { useState, useEffect, useRef } from 'react';
import TLEventCluster from './TLEventCluster';
import AnimateHeight from 'react-animate-height';   // see https://github.com/Stanko/react-animate-height

const TLEventGroup = (props) => {
    const [ selectedClusterInGroup, setSelectedClusterInGroup ] = useState(-1);
    const mainAccItemRef = useRef();
    const [ activeClusterRef, setActiveClusterRef ] = useState(null);

    const selectCluster = (gid, idx, ref) => {
        // console.log('cluster clicked')
        // console.log('selectCluster: idx='+idx+'; groupId='+gid+'; selected is '+selectedClusterInGroup+'; active group is '+props.activeGroup);
        let newSelCluster;
        // console.log(ref);
        if (props.isMobileScreen && idx === selectedClusterInGroup) {
            // can toggle to unselected if in mobile mode
            newSelCluster = -1;
            setSelectedClusterInGroup(newSelCluster);
            props.setSelectedClusterDetail('');
        } else {
            setSelectedClusterInGroup(idx);
            props.setSelectedClusterDetail(`${gid}-${idx}`,true);
        }
        if (ref && newSelCluster >= 0) {
          setActiveClusterRef(ref);
        }
    }

    const clickGroup = () => {
        // console.log('group clicked')
        if (props.isMobileScreen && props.activeGroup !== '' && props.activeGroupRef.current && props.myIdx > props.activeGroup) {
            // if on mobile, scroll the window when a newly opened accordion would move away from where the user clicked; keep the clicked spot stable
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const newCollapsedHeight = mainAccItemRef.current.offsetHeight;
            const oldHeight = props.activeGroupRef.current.offsetHeight;
            // we presume that the new group header height is what the old group header height will become after collapse
            // (these headers have limited height when collapsed, full normal height when expanded)
            // therefore, deltaY is the difference between the whole height of the old & the new header height
            const deltaY = oldHeight - newCollapsedHeight;
            const scrollAmt = Math.min(deltaY, scrollTop);  // can't scroll up any further than page is scrolled down
            window.scrollBy(0, -scrollAmt);
        }
        const selectedClusterInGroupSnapshot = selectedClusterInGroup;
        // if not mobile and -1, set selected cluster to 0:
        const cInG = (!props.isMobileScreen && selectedClusterInGroup === -1 ? 0 : selectedClusterInGroup);
        props.selectGroup(props.myIdx, cInG, mainAccItemRef);

        if (cInG !== selectedClusterInGroupSnapshot) {
            setSelectedClusterInGroup(cInG);
        }
    }

    useEffect(() => {
        // if event group changes (after any selection), pre-select the default -- -1 for mobile or 0 for full-size
        if (props.groupWasSelected) {
            // console.log('useEffect: activeGroup chgd. to '+props.activeGroup+'; setting selectedClusterInGroup to '+(props.isMobileScreen ? -1 : 0));
            setSelectedClusterInGroup(props.isMobileScreen ? -1 : 0);
        }
    }, [props.activeGroup]);

    return (
        <div className="accordion-item"
            ref={mainAccItemRef}
        >
            <div className="accordion-header"
                id={`flush-heading-${props.myIdx}`}
            >
                <button className={`accordion-button tl-eventgroup-button ${(props.isMobileScreen ? 'ismobile' : '')} ${(props.myIdx === props.activeGroup ? '' : 'collapsed')}`}
                    type="button"
                    aria-expanded={(props.myIdx === props.activeGroup)}
                    aria-controls={`flush-collapse-${props.myIdx}`}
                    onClick={clickGroup}
                >
                    <h3 className="tl-eventgroup-hdr">
                        <span className="tl-eventgroup-hdr-date">{props.groupData.date_label}:</span> {props.groupData.primary_name.label}
                    </h3>
                    {
                    // <hr />
                    // <div className={`tl-eventgroup-subhead ${(props.myIdx === props.activeGroup) ? '' : 'twoLineEllipsis'}`}> { /* limit to two lines iff collapsed */ }
                    //     {props.groupData.description} 
                    // </div>
                    }
                </button>
            </div>
            <AnimateHeight
                className={`accordion-collapse`}
                id={`flush-collapse-${props.myIdx}`}
                duration={ props.isMobileScreen ? 0 : 400 /* if mobile, cannot animate because of special auto-scroll behavior */ }
                height={ props.myIdx === props.activeGroup ? 'auto' : 0 }
                aria-labelledby={`flush-heading-${props.myIdx}`}
            >
                <div className="accordion-body">
                    <div className="accordion" id={`TLEventClusterList-${props.myIdx}`}>
                    {
                            props.groupData.part.map((cluster, idx) => {
                                return <TLEventCluster
                                    groupClusterId={`${props.myIdx}-${idx}`}
                                    theSelectedClusterDetail={props.theSelectedClusterDetail}
                                    showingClusterDetail={props.showingClusterDetail}
                                    activeClusterDetail={props.activeClusterDetail}
                                    activeClusterRef={activeClusterRef}
                                    selectedClusterInGroup={selectedClusterInGroup}
                                    activeGroup={props.activeGroup}
                                    myIdx={idx}
                                    parentIdx={props.myIdx}
                                    selectedCluster={selectedClusterInGroup}
                                    clusterId={idx}
                                    key={idx}
                                    selectCluster={selectCluster}
                                    isMobileScreen={props.isMobileScreen}
                                    clusterData={cluster}
                                />
                            })
                    }
                    </div>
                </div>
            </AnimateHeight>
        </div>
    )
}

export default TLEventGroup;