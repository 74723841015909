import React, {useState} from 'react';
import { SearchBar } from './searchBar';
import { useElasticSearch, searchContextToQuery, dateRangeBucketFormatter, dateRangeInputFormatter } from '../util.js';

function DateFilterFirstLastInput(props) {

  // CHECKING YEAR INPUTS:
  const isValidYear = (val) => {
    const isnum = /^\d+$/.test(val);
    return (isnum && val.length === 4);
  }

  const yearInputKeyUp = (e, idx) => {
    const val = e.currentTarget.value;
    const entryOK = (val.length === 0 || isValidYear(val));
    let newInvalids = [...yearsInvalid];
    newInvalids[idx] = !entryOK;
    setYearsInvalid(newInvalids);
    let newEmptys = [...yearsEmpty];
    newEmptys[idx] = (val.length === 0);
    setYearsEmpty(newEmptys);
    // apply button is disabled iff both year fields are empty OR either year field has invalid data
    setApplyYearDisabled(newInvalids[0] || newInvalids[1] || (newEmptys[0] && newEmptys[1]));
    
    // giving the 'benefit of the doubt', clear any error message for this input
    let newErr = [...yearsErrorMsg];
    newErr[idx] = false;
    setYearsErrorMsg(newErr);

    // update the year range values
    let newYr = [...yearRange];
    newYr[idx] = (val.length === 0 ? null : val);
    setYearRange(newYr);
  }

  const yearInputBlur = (e, idx) => {
    const val = e.currentTarget.value;
    const entryOK = (val.length === 0 || isValidYear(val));
    let newErr = [...yearsErrorMsg];
    newErr[idx] = (!entryOK);
    setYearsErrorMsg(newErr);
    // refocus? 
  }

  const yearInputFocus = (e, idx) => {}

  const [ applyYearDisabled, setApplyYearDisabled ] = useState(true);
  const [ yearsInvalid, setYearsInvalid ] = useState([false, false]);
  const [ yearsEmpty, setYearsEmpty ] = useState([true, true]);
  const [ yearsErrorMsg, setYearsErrorMsg ] = useState([false, false]);
  const [ yearRange, setYearRange ] = useState([null, null]);

  return <div className={props.className}>
          <form className="year-select" onSubmit={(e) => {
              e.preventDefault();
              props.applyFilters({ date_range: yearRange }, []);
              if (!props.mobileMode && props.collapseFunc) {
                props.collapseFunc();
              }
            }}>
            <h5>Or enter a range of years:</h5>
            <div className="form-controls">
              <div>
                <label for="year-select-from">From</label>
                <input type="tel" placeholder="yyyy" pattern="[0-9]{4}" minlength="4" maxlength="4" max="9999" id="year-select-from" className="form-control" onFocus={(e) => yearInputFocus(e, 0)} onBlur={(e) => yearInputBlur(e, 0)} onKeyUp={(e) => yearInputKeyUp(e, 0)} />
                <div className={`filters-error-msg ${(yearsErrorMsg[0]) ? '' : 'd-none'}`} id="year-select-from-error">
                  Please enter a 4-digit year
                </div>
              </div>
              <div>
                <label for="year-select-to">To</label>
                <input type="tel" placeholder="yyyy" pattern="[0-9]{4}" minlength="4" maxlength="4" max="9999" id="year-select-to" className="form-control" onFocus={(e) => yearInputFocus(e, 1)} onBlur={(e) => yearInputBlur(e, 1)} onKeyUp={(e) => yearInputKeyUp(e, 1)} />
                <div className={`filters-error-msg ${(yearsErrorMsg[1]) ? '' : 'd-none'}`} id="year-select-to-error">
                  Please enter a 4-digit year
                </div>
              </div>
              <div className="button">
                <button className="btn btn-primary btn-iblock" type="submit" disabled={applyYearDisabled} id="applyYearRange">Apply</button>
              </div>
            </div>
          </form>
        </div>
}

function FacetItemList(props) {
    /*
        Produces a clickable list of items in props.aggregation
    */

    function clickHandler(e, bucket) {
      e.preventDefault();
      if (!bucket) {
        props.clearFilter(props.filterName);
      } else {
        let result = {};
        switch (props.filterName) {
          case 'date_range':
            // console.log(bucket);
            result[props.filterName] = dateRangeInputFormatter(bucket);
            break;
          default:
            result[props.filterName] = bucket.key.label;
            break;
        }
        props.applyFilters(result, props.filterUnsets || []);
      }
      // close the filter pane upon selection:
      if (!props.mobileMode && props.collapseFunc) {
        props.collapseFunc();
      }
    }

    const doesBucketMatchCurrentFilter = (bucket) => {
      let result;
      switch (props.filterName) {
        case 'date_range':
          if (!props.filters || !props.filters[props.filterName] || props.filters[props.filterName].length !== 2 || !bucket.key) {
            result = false;
          } else {
            // undefined must match undefined
            const fromMatch = (!props.filters[props.filterName][0])
              ? (!bucket.key.from_as_string)
              : (props.filters[props.filterName][0].toString() === bucket.key.from_as_string);
            const toMatch = (!props.filters[props.filterName][1])
              ? (!bucket.key.to_as_string)
              : (props.filters[props.filterName][1].toString() === bucket.key.to_as_string);
            // console.log('from as string = '+bucket.key.from_as_string+'; to as string = '+bucket.key.to_as_string);
            // console.log('fromMatch = '+fromMatch+'; toMatch = '+toMatch);
            result = fromMatch && toMatch;
          }
          break;
        case 'entity_type':
        case 'classification':
        default:
          if (!props.filters || !props.filters[props.filterName] || !bucket.key) {
            result = false;
          } else {
            result = (props.filters[props.filterName] === bucket.key.label);
          }
          break;
      }
      return result;
    }

    if (props.sortByCount) {
      props.aggregation.sort((bucket1,bucket2) => { return bucket2.active.doc_count - bucket1.active.doc_count })
    }

    return <div className={props.className}>
      <h5>{props.heading || props.name}</h5>
      <div className="landing-filters-dropdown">
        <ul className="landing-filters-dropdown-menu">
          <li>
            <a className={`dropdown-item ${!props.filters || !props.filters[props.filterName] ? 'active' : ''}`}
              href="javascript:;"
              onClick={(e) => clickHandler(e, null)}
            >Any ({props.count})</a>
          </li>
          { props.aggregation.map( (bucket) => (
          <li>
            <a className={`dropdown-item ${doesBucketMatchCurrentFilter(bucket) ? 'active' : ''}`}
              href="javascript:;"
              onClick={(e) => clickHandler(e, bucket)}
            >{ bucket.key.label } ({ bucket.active.doc_count })</a>
          </li> ) ) }
        </ul>
      </div>
    </div>
}

const bucketMergeReducer = (acc,[key,value]) => {
  // console.log(acc,key,value)

  if ( key in acc ) {
    if (key==="*-1911") {
    console.log(JSON.parse(JSON.stringify(acc[key])))
    console.log(JSON.parse(JSON.stringify(value)))
    }
    acc[key].doc_count = acc[key].doc_count + value.doc_count
    acc[key].active.doc_count = acc[key].active.doc_count + value.active.doc_count
  } else if (acc !== undefined) {
    acc[key] = value
  }

  return acc
}

const mergeAggregations = (...aggs) => {

  let result = {}
  console.log(JSON.parse(JSON.stringify(aggs)))
  aggs.filter( item => item !== undefined).forEach( agg => Object.entries(agg).reduce( bucketMergeReducer, result ) );

  return result

}

const TimeframeInputs = (props) => {

    // FIXME: This mergeAggregations kludge is a client-side merge of the various "date_range" aggregations

    // console.log(JSON.parse(JSON.stringify(timeFacetBuckets)))
    return (
      <div className="filters-flex-container">
        <div className={`landing-filters-cell ${props.mobileMode ? '' : 'landing-filters-btr'}`}>
          <FacetItemList className=""
            sortByCount={false}
            name="Select a period of interest:"
            filterName="date_range"
            filters={props.filters}
            applyFilters={props.applyFilters}
            clearFilter={props.clearFilter}
            aggregation={ dateRangeBucketFormatter(mergeAggregations(props.aggregations?.creation_date_range?.buckets, props.aggregations?.life_date_range?.buckets, props.aggregations?.active_date_range?.buckets, props.aggregations?.timespan_date_range?.buckets)) }
            count={props.count}
            collapseFunc={props.collapseFunc}
            mobileMode={props.mobileMode}
          />
        </div>

        <div className="landing-filters-cell landing-filters-bt">
          <DateFilterFirstLastInput className=""
            filters={props.filters}
            applyFilters={props.applyFilters}
            collapseFunc={props.collapseFunc}
            mobileMode={props.mobileMode}
          />
        </div>

        <div className="d-none d-md-block ml-auto pl-3 landing-filters-cell landing-filters-bt">
          <button type="button" className="btn btn-primary search-filt-close">
            <i className="icon icon-sm button-icon icon-close-button-x icon--teal mr-2"></i> Close
          </button>
        </div>
      </div>
    )
}

const EntityTypeInputs = (props) => {

    // Callback for Array.map() that normalizes entity display types (eg, TimelineEvent => Event)
    const bucketKeyNormalizer = (bucket) => {
      if (bucket.key.label == "TimelineEvent") {
        bucket.key.label = "Event"
      }

      return bucket
    }

    return (
      <div className="filters-flex-container">
        <div className={`landing-filters-cell ${props.mobileMode ? '' : 'landing-filters-btr'} ${ props.showTypeFilter ? '' : 'd-none'}`}>
          <FacetItemList className=""
            sortByCount={true}
            name="By type"
            filterName="entity_type"
            filters={props.filters}
            applyFilters={props.applyFilters}
            clearFilter={props.clearFilter}
            aggregation={ (props.aggregations?.entity_type) ? props.aggregations.entity_type.buckets.map( bucketKeyNormalizer ) : [] }
            count={props.count}
            collapseFunc={props.collapseFunc}
            mobileMode={props.mobileMode}
          />
        </div>

        <div className="landing-filters-cell landing-filters-bt">
          <FacetItemList className=""
            sortByCount={true}
            name="Subtype"
            heading="By subtype"
            filterName="classification"
            filters={props.filters}
            applyFilters={props.applyFilters}
            clearFilter={props.clearFilter}
            aggregation={ props.aggregations?.classification?.buckets.length > 0 ? props.aggregations?.classification?.buckets.filter( item => !( [ 'T2-context', 'T3-major-events', 'T1-Schloss-related' ].includes(item.key.label)  ) ) : [] }
            count={props.count}
            collapseFunc={props.collapseFunc}
            mobileMode={props.mobileMode}
          />
        </div>

        <div className="d-none d-md-block ml-auto pl-3 landing-filters-cell landing-filters-bt">
          <button type="button" className="btn btn-primary search-filt-close">
            <i className="icon icon-sm button-icon icon-close-button-x icon--teal mr-2"></i> Close
          </button>
        </div>
      </div>
    )
}

const LocationInputs = (props) => {
    return (
        <div className="filters-flex-container">
          <div className={`landing-filters-cell ${props.mobileMode ? '' : 'landing-filters-btr'}`}>
            <FacetItemList className=""
              sortByCount={true}
              name="Country"
              filterName="city"
              filterUnsets={['address']/* if selecting a city, address is unset */}
              filters={props.filters}
              applyFilters={props.applyFilters}
              clearFilter={props.clearFilter}
              aggregation={ (props.aggregations?.country) ? props.aggregations.country.buckets : [] }
              count={props.count}
              collapseFunc={props.collapseFunc}
              mobileMode={props.mobileMode}
            />
          </div>
          <div className={`landing-filters-cell ${props.mobileMode ? 'landing-filters-bt' : 'landing-filters-btr'}`}>
            <FacetItemList className=""
              sortByCount={true}
              name="City"
              filterName="address"
              filterUnsets={[]}
              filters={props.filters}
              applyFilters={props.applyFilters}
              clearFilter={props.clearFilter}
              aggregation={ (props.aggregations?.city) ? props.aggregations.city.buckets : [] }
              count={props.count}
              collapseFunc={props.collapseFunc}
              mobileMode={props.mobileMode}
            />
          </div>
          <div className="landing-filters-cell landing-filters-bt">
              <h5>Or find a location</h5>
                <SearchBar
                  searchBarClassName="filters-search-bar"
                  endpoint={props.endpoint}
                  searchButton={false}
                  mobileMode={props.mobileMode}
                />
              <p>Type in a town/city, street, or building name.</p>
          </div>
          <div className="d-none d-md-block ml-auto pl-3 landing-filters-cell landing-filters-bt">
            <button type="button" className="btn btn-primary search-filt-close">
              <i className="icon icon-sm button-icon icon-close-button-x icon--teal mr-2"></i> Close
            </button>
          </div>
        </div>
    )
}

export { TimeframeInputs, EntityTypeInputs, LocationInputs };