import React, { useState, useEffect, useRef } from 'react';
import PerspectiveList from './perspectiveList.js';
import AnimateHeight from 'react-animate-height';

const TLEvent = (props) => {
    const [ expanded, setExpanded ] = useState(false);
    const eventAccItemRef = useRef();
    const [ activePerspId, setActivePerspId ] = useState('');

    // for the Perspective tabs:
    const [ activePerspTab, setActivePerspTab ] = useState(props.defaultPerspTab);
    const [ showPerspTab, setShowPerspTab ] = useState(props.defaultPerspTab);
    const setThePerspTab = (idx) => {
        // need to distinguish show & active for purposes of fade effect
        setShowPerspTab(null);
        // delay for purpose of fade effect on tab content
        setTimeout(() => {
            setActivePerspTab(idx);
            setTimeout(() => {
                setShowPerspTab(idx);
            }, 15);
        }, 150);   // this should match the transition time on .fade
    }

    const clickEvent = () => {
        // console.log('clickEvent ' + props.myIdx + '; eventOpenIdx = ' + props.eventOpenIdx);
        // console.log('height = ' + refCollapsible.current.clientHeight);
        // if mobile, allow only one to be open at a time
        if (props.isMobileScreen) {
            if (props.eventOpenIdx === props.myIdx) {
                // closing accordion item
                props.setEventOpenIdx(-1);
            } else {
                // may need to auto-scroll when opening new event
                if (props.setScrollWindowBy && props.eventOpenIdx >= 0 && props.activeEventRef.current && props.myIdx > props.eventOpenIdx) {
                    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                    const newEventCollapsedHeight = eventAccItemRef.current.offsetHeight;
                    const oldEventHeight = props.activeEventRef.current.offsetHeight;
                    const deltaY = oldEventHeight - newEventCollapsedHeight;
                    const scrollAmt = Math.min(deltaY, scrollTop);  // can't scroll up any further than page is scrolled down
                    window.scrollBy(0, -scrollAmt);
                    // handle the scrolling at a higher level for better sync?
                    //props.setScrollWindowBy(-scrollAmt);
                    //props.setScrollWindowBy(scrollTop-scrollAmt);
                }
                props.setEventOpenIdx(props.myIdx);
                props.setActiveEventRef(eventAccItemRef);
            }
        }
        if (!expanded) {
            // opening; reset the shown/active perspective tab
            setActivePerspTab(props.defaultPerspTab);
            setShowPerspTab(props.defaultPerspTab);
        }
        const newExp = !expanded;
        setExpanded(newExp);
        props.setExpandedIdx(props.myIdx, newExp);
    }

    // only used in mobile mode:
    const clickPerspAccordionTab = (idx, elementId) => {
        if (idx === showPerspTab) {
            // close up the accordion
            setActivePerspTab(props.defaultPerspTab);
            setShowPerspTab(props.defaultPerspTab);
        } else {
            // may need to auto-scroll when opening new event -- only if the selected is below the current
            if (showPerspTab >= 0 && activePerspId !== '' && idx > showPerspTab) {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                // FIXME: avoid jquery?
                const newCollapsedHeight = $('#'+elementId).height();
                // console.log('newCollapsedHeight = ' + newCollapsedHeight);
                const oldHeight = $('#'+activePerspId).height();
                // console.log('oldHeight = ' + oldHeight);
                const deltaY = oldHeight - newCollapsedHeight;
                const scrollAmt = Math.min(deltaY, scrollTop);  // can't scroll up any further than page is scrolled down
                window.scrollBy(0, -scrollAmt);
            }
            setShowPerspTab(idx);
            setActivePerspTab(idx);
            setActivePerspId(elementId);
        }
    }

    const goToEventRecord = (data) => {
        // console.log('event link clicked');
        // console.log(data);
        var url = new URL(window.location.href);
        const targetPath = data._id.replace('http://www.collectionbrowse.org', '');
        url.pathname = targetPath;
        window.location = url.toString();
    }

    // mobile only: if the open event changes, and it's not us, then reset expanded and the shown/active perspective tab:
    useEffect(() => {
        // console.log('useEffect: eventOpenIdx changed to ' + props.eventOpenIdx);
        if (props.isMobileScreen) {
            if (props.eventOpenIdx !== props.myIdx && expanded) {
                setExpanded(false);
                props.setExpandedIdx(props.myIdx, false);
                setActivePerspTab(props.defaultPerspTab);
                setShowPerspTab(props.defaultPerspTab);
            }
        }
    }, [props.eventOpenIdx]);

    useEffect(() => {
        // console.log('useEffect: activeClusterDetail changed to ' + props.activeClusterDetail);
        if (expanded) {
            setExpanded(false);
            props.setExpandedIdx(props.myIdx, false);
            setActivePerspTab(props.defaultPerspTab);
            setShowPerspTab(props.defaultPerspTab);
        }
    }, [props.activeClusterDetail]);

    useEffect(() => {
        setActivePerspTab(props.defaultPerspTab);
        setShowPerspTab(props.defaultPerspTab);
    }, [props.activeGroup]);

    // if (!props.eventData.part || props.eventData.part.length === 0)
        // console.log(props.eventData);

    return (
        <div className={`card accordion-item tl-detail-acc-card ${props.isMobileScreen ? 'ismobile' : ''} ${(props.isMobileScreen ? (props.eventOpenIdx === props.myIdx) : expanded) ? 'expanded' : ''}`}
            ref={eventAccItemRef}
        >
            <div className="tl-detail-acc-hdg">
                <button className={`btn-block text-left accordion-button tl-detail-acc-button ${props.isMobileScreen ? 'ismobile' : ''} ${(props.isMobileScreen ? (props.eventOpenIdx === props.myIdx) : expanded) ? '' : 'collapsed'}`}
                    type="button"
                    id={`detail-accordion-hdg-${props.groupClusterEventId}`}
                    aria-expanded={(props.isMobileScreen ? (props.eventOpenIdx === props.myIdx) : expanded)}
                    aria-controls={`detail-accordion-collapse-${props.groupClusterEventId}`}
                    onClick={clickEvent}
                >
                    <h5 className={`tl-detail-acc-hdr ${props.isMobileScreen ? 'ismobile' : ''}`}>
                        <div className="tl-detail-acc-hdr-hdln">{props.eventData?.date_label ?? "FIXME"}</div>
                        <div>{props.eventData?.primary_name?.label ?? "FIXME"}</div>
                    </h5>{ /* hide the HR if the next event is expanded (see issue #57): */ }
                    <hr className={`${(props.isMobileScreen ? (props.eventOpenIdx === props.myIdx + 1) : props.nextExpanded) ? 'transpar' : ''}`} />

                </button>
            </div>

            <AnimateHeight
                id={`detail-accordion-collapse-${props.groupClusterEventId}`}
                duration={ props.isMobileScreen ? 0 : 400 /* if mobile, cannot animate because of special auto-scroll behavior */ }
                height={ (props.isMobileScreen ? (props.eventOpenIdx === props.myIdx) : expanded) ? 'auto' : 0 }
                aria-labelledby={`detail-accordion-hdg-${props.groupClusterEventId}`}
            >
                <div className={`tl-detail-acc-bod ${props.isMobileScreen ? 'ismobile' : ''}`}>
                    <p>{props.eventData?.description}</p>
                    {props.eventData?.part?.length > 0 &&
                        <p>There are multiple perspectives about what happened on this date.</p>
                    }

                    { /* == Perspectives: Use tabs for full-size mode, accordion for mobile mode. ====================

                        Perspectives as TABS: =======================================
                    */ }

                    {!props.isMobileScreen && props.eventData?.part?.length > 0 &&
                        <ul className="nav nav-tabs tl-persp-tabs" role="tablist" aria-label="Perspectives">
                        {
                            props.eventData?.part?.map((hit, idx) => {
                                return <li key={idx} className="nav-item tl-persp-tab">
                                    <a className={`nav-link ${(idx === activePerspTab ? 'active' : '')}`}
                                        id={`TLDetailTab-${props.groupClusterEventId}-${idx}`}
                                        href="javascript:;"
                                        role="tab"
                                        aria-controls={`TLDetailTabPanel-${props.groupClusterEventId}-${idx}`}
                                        aria-selected={(idx === activePerspTab)}
                                        onClick={() => setThePerspTab(idx)}
                                    >
                                        <h6>Perspective {idx+1}</h6>
                                    </a>
                                </li>
                            }) ?? ''
                        }
                        </ul>
                    }
                    {!props.isMobileScreen &&
                        <div className="tab-content">
                        { /* This concat() adds data for the top-level event if there are no perspectives */
                            props.eventData?.part?.concat(props.eventData?.part?.length > 0 ? [] : [{_id: props.eventData?._id ?? '', related: props.eventData?.related ?? []}]).map((hit, idx) => {
                                return <div key={idx} className={`tab-pane fade ${(idx === activePerspTab ? 'active' : '')} ${(idx === showPerspTab ? 'show' : '')}`}
                                    id={`TLDetailTabPanel-${props.groupClusterEventId}-${idx}`}
                                    role="tabpanel"
                                    aria-labelledby={props.eventData?.part?.length > 0 ? `TLDetailTab-${props.groupClusterEventId}-${idx}` : null}
                                    aria-expanded={(idx === activePerspTab)}
                                    aria-hidden={(idx !== showPerspTab)}
                                >
                                    <div className="tl-detail-tab-content">
                                        <p className="tl-persp-title"><strong>{hit.primary_name?.label ?? ''}</strong></p>
                                        <p className="tl-persp-text">{hit.description ?? ''}</p>

                                        <div className="pb-3">
                                            <button type="button" className="btn jd-secondary-button" onClick={() => goToEventRecord(hit)}>More About This Event
                                                <span className="icon button-icon icon-arrow-rt0 icon--jd-teal"></span>
                                            </button>
                                        </div>

                                        <p className="tl-persp-text"> {/* More long-form description.. */}</p>

                                        <PerspectiveList related={hit.related} key={`TLPerspList-${props.groupClusterEventId}-${idx}` } />
                                    </div>
                                </div>
                            }) ?? ''
                        }
                        </div>
                    }
                    { /*
                        Perspectives as ACCORDION: =======================================
                    */ }
                    {props.isMobileScreen &&
                        <div className="accordion">
                        { /* This concat() adds data for the top-level event if there are no perspectives */
                            props.eventData?.part?.concat(props.eventData?.part?.length > 0 ? [] : [{_id: props.eventData?._id ?? '', related: props.eventData?.related ?? []}]).map((hit, idx) => {
                                return (
                                <div className="accordion-item m-0"
                                    id={`TLDetailPerspAccItem-${props.groupClusterEventId}-${idx}`}
                                >
                                    { /* If only showing 1 "perspective", hide the accordion header, and always show the corresponding acc. body, below */ }
                                    <div className={props.eventData?.part?.length > 0 ? "accordion-header" : "accordion-header d-none"}>
                                        <button className={`persp-acc-button ${(idx === showPerspTab ? '' : 'collapsed')}`}
                                            id={`TLDetailColControl-${props.groupClusterEventId}-${idx}`}
                                            type="button"
                                            aria-expanded={(idx === showPerspTab)}
                                            aria-controls={`TLDetailCol-${props.groupClusterEventId}-${idx}`}
                                            onClick={() => clickPerspAccordionTab(idx, `TLDetailPerspAccItem-${props.groupClusterEventId}-${idx}`)}
                                        >
                                            <h6>Perspective {idx+1}</h6>
                                        </button>
                                    </div>
                                    <div
                                        className={`accordion-collapse persplist-tab-collapse collapse ${(idx === showPerspTab || props.eventData?.part?.length === 0 ? 'show' : '')}`}
                                        id={`TLDetailCol-${props.groupClusterEventId}-${idx}`}
                                        aria-labelledby={props.eventData?.part?.length > 0 ? `TLDetailColControl-${props.groupClusterEventId}-${idx}` : null}
                                    >
                                        <div className="tl-detail-tab-content ismobile">
                                            <p className="tl-persp-title"><strong>{hit.primary_name?.label ?? ''}</strong></p>
                                            <p className="tl-persp-text">{hit.description ?? ''}</p>

                                            <div className="pb-3">
                                                <button type="button" className="btn jd-secondary-button" onClick={() => goToEventRecord(hit)}>More About This Event
                                                    <span className="icon button-icon icon-arrow-rt0 icon--jd-teal"></span>
                                                </button>
                                            </div>

                                            <p className="tl-persp-text"> {/* More long-form description.. */}</p>

                                            <PerspectiveList related={hit.related} key={`TLPerspList-${props.groupClusterEventId}-${idx}`} />
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                        }
                        </div>
                    }
                </div>
            </AnimateHeight>
        </div>
    )
}

export default TLEvent;