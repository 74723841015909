import React, { useState, useRef, useEffect, useContext } from 'react';
import { useElasticSearch, inventoriesContextToQuery } from './util';

const InventoryItem = (props) => {
  // console.log(props)
  return              <div className="inventory-item">
              <h3>{props.about.label}</h3>

        <div className="inventory-item-content">
                <div className="inventory-label-value-pairs">
                  <dl>
                    { [ ['ID',props.ident], 
                      ['Page',props.page], 
                      ['Title',props.title], 
                      ['Artist',props.artist], 
                      ['Measurements',props.dimension], 
                      ['Exhibitions',props.exhibition] ].map( ([label,data]) => {
                        if (data) {
                          return ( <React.Fragment><dt className="person-page-info-heading">{label}</dt>
                                    <dd className="person-page-info">
                                      {data}
                                    </dd></React.Fragment> )                          
                        }
                      }) }
                  </dl>
                </div>
                { /* some tricky stuff:
                  at smaller screen size than "lg" (992), force a break in the flex flow
                  in CSS, the 'view' link becomes justified center below that same width 
                */ }
                {
                  props.representation?.length > 0 ? ( <React.Fragment><div className="inventory-flex-break d-lg-none"></div>
                <div className="inventory-pic-container">
                  <a className="inventory-pic-link" href={props.about.link.replace('http://www.collectionbrowse.org','')}><img className="person-page-thumb" alt="Scan of a page from the inventory document" src={props.representation[0].link} /></a>
                </div>
                <div className="inventory-flex-break"></div></React.Fragment> ) : ''
                }
                
                <div className="inventory-item-textline">
                  <a className="inventory-link" href={props.about.link.replace('http://www.collectionbrowse.org','')}>View inventory document</a>
                </div>
              </div>
              </div>
} 

const InventoriesTab = (props) => {
  const [ident, setIdent] = useState(props.ident)
  const [endpoint, setEndpoint] = useState(props.endpoint)
  const [query, setQuery] = useState(inventoriesContextToQuery(props.ident))
  
  const results = useElasticSearch(endpoint,query)
  // console.log(results)

    return (
        <div
          className={`tab-pane clearfix fade ${props.show ? 'show' : ''} ${props.active ? 'active' : ''}`}
          id="inventories"
          role="tabpanel"
          aria-labelledby="inventories-tab"
          aria-expanded={props.active}
          aria-hidden={!props.active}
        >
          <div className="container-fluid">
            { results?.hits?.hits[0]?._source?.inventory?.map( item => <InventoryItem {...item} /> )  }
          </div>
        </div>
    )
}

export { InventoriesTab };