import React, { useState, useEffect } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { isLocalUrl } from './util.js';

const EssayViewController = (props) => {
  // we'll use this data structure to collect info on the elements as we parse them:
  // the Nav needs to be dropped into the DOM at the right point, though it occurs at the beginning of the list of elements imported
  const htmlBitsStartVal = {
    'elements': [],
    'navFound': false,
    'nav': null,
    'numParasDone': 0,  // policy is that the 'intro' section ends after the first numIntroParas P tags
    'introN': 0
  };
  const numIntroParas = 2;

  const [ isAboutPage, setIsAboutPage ] = useState(false);

  const [ htmlIntro, setHtmlIntro ] = useState([]);
  const [ htmlRemainder, setHtmlRemainder ] = useState([]);
  const [ htmlNav, setHtmlNav ] = useState();

  const options = {
    replace: (domNode) => {
      if (!domNode.attribs) {
        return;
      }

      // replace a P element that contains only an IMG element w. a DIV:
      if (domNode.name === 'p' && domNode.children && domNode.children.length === 1 && domNode.children[0].name === 'img') {
        return React.createElement(
          'div',
          { className: 'essay-img-container mx-auto' },
          domToReact(domNode.children, options)
        );
      }

      if (domNode.name === 'a' && !isLocalUrl(domNode.attribs.href)) {
        // console.log(domNode.attribs);
        // console.log(domNode.attribs.target !== '_blank');
        domNode.attribs.target = '_blank';
        return domNode;
      }
      
      // replace IMG element's SRC w. placeholder image:
      if (domNode.name === 'img') {
        // console.log(domNode.attribs);
        // domNode.attribs.src = 'https://via.placeholder.com/412x309';
        domNode.attribs.style = 'width: 90%; height: auto;';
        return domNode;
      }
    },
    trim: true
  };

  useEffect(() => {
    const parsedHtml = parse(props.essayHtml, options);
    // console.log(parsedHtml);
    // first tag: NAV -- save it until after the intro section
    // intro section consists of all tags through the first H1
    // - introN contains the number of tags in intro section
    const newHtmlBits = parsedHtml.reduce((acc, val) => {
      if (val.type === 'nav') {
        if (!acc.navFound) {
          acc.nav = val;
        }
        acc.navFound = true;
      } else {
        acc.elements.push(val);
        if (false && val.type === 'p') {  // not counting p tags anymore
          acc.numParasDone++;
          if (acc.numParasDone === numIntroParas) {
            // we have our intro section; mark the # of elements in it, then add the nav
            acc.introN = acc.elements.length;
          }
        }
        if (val.type === 'h1') {
          // we have our intro section; mark the # of elements in it
          acc.introN = acc.elements.length;
          // console.log('H1:');
          // console.log(val);
          if (val.props?.id === 'about') {
            setIsAboutPage(true);
          }
        }
      }
      return acc;
    }, htmlBitsStartVal);
    // console.log(newHtmlBits);
    setHtmlIntro(newHtmlBits.elements.slice(0, newHtmlBits.introN));
    setHtmlRemainder(newHtmlBits.elements.slice(newHtmlBits.introN));
    setHtmlNav(newHtmlBits.nav);
  }, [props.essayHtml]);

  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-12 p-0">
          <div className="essay-import">
            <div className="essay-intro">
              <div className="essay-intro-text">
                <p className={`essay-category ${isAboutPage ? 'd-none' : ''}`}>{props.category}</p>
                {htmlIntro}
              </div>
            </div>
            <div className="essay-post-intro">
              {htmlNav}
              <div className="essay-post-intro-main">
                {htmlRemainder}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { EssayViewController };