import React, { useState, useRef, useEffect, useContext } from 'react';

const EventsTab = (props) => {
    return (
        <div
          className={`tab-pane clearfix fade ${props.show ? 'show' : ''} ${props.active ? 'active' : ''}`}
          id="events"
          role="tabpanel"
          aria-labelledby="events-tab"
          aria-expanded={props.active}
          aria-hidden={!props.active}
        >
          <div className="container-fluid" style={{height: '400px', padding: '1.5rem 15px'}}>
          Data to come. Please refer to the wireframes for details on what will be here: <a className="emphatic-a" href="https://yjilbd.axshare.com" target="_blank">https://yjilbd.axshare.com</a> password: jdcrp2021
          </div>
        </div>
    )
}

export { EventsTab };