import React, {useState, useEffect, useContext} from 'react';
import ReactDOM from 'react-dom';

import '../css/main.css';
import '../css/main.scss';

import {SearchView} from './views.js';
import { TabViewController } from './tabs.js';
import { ImageViewer, normalizeTileSource } from './components/imageViewer.js';
import {paramsToState} from './util.js';

import { EssayViewController } from './essay.js';
import { HomePageView } from './components/homePageView.js';
import { EssayHomeView } from './components/essayHomeView.js';

import cclogo from '../images/CC-logo-18-1.png';
import euflag from '../images/eu-5000100-flag-2.png';
import metz from '../images/Metzinger-placeholder.png';
import metz1 from '../images/Metzinger-placeholder-test.png';
import vichy1 from '../images/vichy-france-map-test.png';
import schlosscoll from '../images/example-hero-image-schloss-coll.jpg';
import homepagecollage from '../images/homepage-collage.png';
import explorebg from '../images/explore-bg.png';

import osd0 from '../../node_modules/openseadragon/build/openseadragon/images/button_grouphover.png';
import osd1 from '../../node_modules/openseadragon/build/openseadragon/images/button_hover.png';
import osd2 from '../../node_modules/openseadragon/build/openseadragon/images/button_pressed.png';
import osd3 from '../../node_modules/openseadragon/build/openseadragon/images/button_rest.png';
import osd4 from '../../node_modules/openseadragon/build/openseadragon/images/flip_grouphover.png';
import osd5 from '../../node_modules/openseadragon/build/openseadragon/images/flip_hover.png';
import osd6 from '../../node_modules/openseadragon/build/openseadragon/images/flip_pressed.png';
import osd7 from '../../node_modules/openseadragon/build/openseadragon/images/flip_rest.png';
import osd8 from '../../node_modules/openseadragon/build/openseadragon/images/fullpage_grouphover.png';
import osd9 from '../../node_modules/openseadragon/build/openseadragon/images/fullpage_hover.png';
import osd10 from '../../node_modules/openseadragon/build/openseadragon/images/fullpage_pressed.png';
import osd11 from '../../node_modules/openseadragon/build/openseadragon/images/fullpage_rest.png';
import osd12 from '../../node_modules/openseadragon/build/openseadragon/images/home_grouphover.png';
import osd13 from '../../node_modules/openseadragon/build/openseadragon/images/home_hover.png';
import osd14 from '../../node_modules/openseadragon/build/openseadragon/images/home_pressed.png';
import osd15 from '../../node_modules/openseadragon/build/openseadragon/images/home_rest.png';
import osd16 from '../../node_modules/openseadragon/build/openseadragon/images/next_grouphover.png';
import osd17 from '../../node_modules/openseadragon/build/openseadragon/images/next_hover.png';
import osd18 from '../../node_modules/openseadragon/build/openseadragon/images/next_pressed.png';
import osd19 from '../../node_modules/openseadragon/build/openseadragon/images/next_rest.png';
import osd20 from '../../node_modules/openseadragon/build/openseadragon/images/previous_grouphover.png';
import osd21 from '../../node_modules/openseadragon/build/openseadragon/images/previous_hover.png';
import osd22 from '../../node_modules/openseadragon/build/openseadragon/images/previous_pressed.png';
import osd23 from '../../node_modules/openseadragon/build/openseadragon/images/previous_rest.png';
import osd24 from '../../node_modules/openseadragon/build/openseadragon/images/rotateleft_grouphover.png';
import osd25 from '../../node_modules/openseadragon/build/openseadragon/images/rotateleft_hover.png';
import osd26 from '../../node_modules/openseadragon/build/openseadragon/images/rotateleft_pressed.png';
import osd27 from '../../node_modules/openseadragon/build/openseadragon/images/rotateleft_rest.png';
import osd28 from '../../node_modules/openseadragon/build/openseadragon/images/rotateright_grouphover.png';
import osd29 from '../../node_modules/openseadragon/build/openseadragon/images/rotateright_hover.png';
import osd30 from '../../node_modules/openseadragon/build/openseadragon/images/rotateright_pressed.png';
import osd31 from '../../node_modules/openseadragon/build/openseadragon/images/rotateright_rest.png';
import osd32 from '../../node_modules/openseadragon/build/openseadragon/images/zoomin_grouphover.png';
import osd33 from '../../node_modules/openseadragon/build/openseadragon/images/zoomin_hover.png';
import osd34 from '../../node_modules/openseadragon/build/openseadragon/images/zoomin_pressed.png';
import osd35 from '../../node_modules/openseadragon/build/openseadragon/images/zoomin_rest.png';
import osd36 from '../../node_modules/openseadragon/build/openseadragon/images/zoomout_grouphover.png';
import osd37 from '../../node_modules/openseadragon/build/openseadragon/images/zoomout_hover.png';
import osd38 from '../../node_modules/openseadragon/build/openseadragon/images/zoomout_pressed.png';
import osd39 from '../../node_modules/openseadragon/build/openseadragon/images/zoomout_rest.png';

const VIEW_TYPES = {
    SEARCH: 'SEARCH'
}

function ContentView(props) {

    switch (props.view) {
        case VIEW_TYPES.SEARCH:
            let state = paramsToState()
            return <SearchView endpoint={props.endpoint} type={props.type} queryString={''} filters={[]} {...state} ></SearchView>
            break
    }

}

class App extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            title: props.title,
            endpoint: props.endpoint,
            view: props.view,
            type: props.type
            // navItems: [ "Home", "About", "Artists", "Locations"]
        }
    }

    render() {

        return <ContentView view={this.state.view} type={this.state.type} endpoint={this.state.endpoint}></ContentView>
               
    }
}

window.addEventListener('DOMContentLoaded', (event) => {

    if (document.getElementById('appRoot')) {
        ReactDOM.render(<App {...appRoot.dataset} />, document.getElementById('appRoot'));
    }

    if (document.getElementById('essayHomeRoot')) {
        ReactDOM.render(<EssayHomeView {...essayHomeRoot.dataset} />, document.getElementById('essayHomeRoot'));
    }

    if (document.getElementById('homePageRoot')) {
        ReactDOM.render(<HomePageView {...homePageRoot.dataset} />, document.getElementById('homePageRoot'));
    }

    const el = document.getElementById('essayPgRoot');
    if (el) {
        ReactDOM.render(<EssayViewController {...essayPgRoot.dataset} essayHtml={el.innerHTML} />, el);
    }

    if (document.getElementById('tabRoot')) {
        ReactDOM.render(<TabViewController {...tabRoot.dataset} filters={[]} />, document.getElementById('tabRoot'));
    }

    if (document.getElementById('entityImageViewerRoot')) {
        ReactDOM.render(<ImageViewer {...entityImageViewerRoot.dataset} images={JSON.parse(entityImageViewerRoot.dataset.representation)?.map( item => normalizeTileSource(item) ) ?? []} />, document.getElementById('entityImageViewerRoot'));
    }


});