import React, { useState, useRef, useEffect, useContext } from 'react';
import { useElasticSearch, relationshipsContextToQuery } from './util.js';
import MultiCarouselR from './components/multiCarousel';

const RelationshipListItem = (props) => {

  if (props.result.viewall && props.result.viewall > 0) {
      return (
          <div className="item">
              <div className="grid-item-sm relationship-viewall-item">
                  <a className="rel-item-link1" href="javascript:;">
                      <div className="grid-image-container-sm grid-viewall">
                          <figure>
                              <div className="icon icon-relations-list icon-relations-viewall icon-boxed jd-icon-viewall"></div>
                              <figcaption> </figcaption>
                          </figure>
                      </div>
                      <div className="grid-relations-text">
                          <a className="grid-viewall-link">View All<br />({props.result.viewall})</a>
                      </div>
                  </a>
              </div>
          </div>
      );
  }

  // PROBLEM: in some browsers, the onfocus and onclick events seem to happen in an indeterminate order.
  // esp. chrome on mac - see issue #147. 
  // the result is that sometimes, in those browsers, the onclick event doesn't trigger opening the QV.
  // SOLUTION: break down the 'click' event into mousedown + mouseup
  // in onmousedown, preventDefault so the focus doesn't happen
  // in onmouseup, do the focus stuff
  // NOTES: onclick would handle the Enter keypress, but onmouseup doesn't, so we need an extra handler for the Enter key

  const itemMouseDownHandler = (event) => {
    event.preventDefault();
  }

  const itemMouseUpHandler = (event) => {
    console.log('click handler: calling itemClickAction; id = '+props.result._id);
    props.itemClickAction(event, { '_id': props.result._id, ...props.result._source });
    // make sure clicked item has focus - see https://zellwk.com/blog/inconsistent-button-behavior/
    event.currentTarget.classList.toggle('focus-outline-none', true);   // disable focus outline, only when clicking, not tabbing
    event.currentTarget.focus();
    // props.focusfunc(event);
    props.storeActive();
  }

  const itemFocusHandler = (event) => {
    props.focusfunc(event);
  }

  const itemKeyUpHandler = (event) => {
    if (event.key === 'Enter') {
      // console.log('enter key');
      props.itemClickAction(event, { '_id': props.result._id, ...props.result._source });
      props.storeActive();
    }
  }

  function eventDateFormatYear(date) {
    let dateFormatter = new Intl.DateTimeFormat('en', {dateStyle: 'medium', 'timeZone': 'UTC'});
    let formatted = dateFormatter.formatToParts(date).filter( datePart => datePart.type !== 'literal' ).reduce( (acc,cur) => { return cur.type==='year' ? `${cur.value}` : '' }, '' );
    return formatted;
  }

  // sort of duplicates the eventNormalize() function
  // shows year only
  const getTimespanLabel = (timespan) => {
    var dateLabel = timespan.date_label;
    let dateFirst = timespan.date_first;
    let dateLast = timespan.date_last;

    if ( dateLabel !== undefined && dateLabel !== '' ) {
      // kludgey:
      const dashIdx = dateLabel.indexOf('-');
      if (dashIdx >= 0) {
        dateLabel = dateLabel.substring(0, dashIdx);
      }
    } else if ( dateFirst === dateLast ) {
      let date = new Date(dateFirst * 1000)
      dateLabel = eventDateFormatYear(date)
    } else if (dateFirst < dateLast || dateFirst > dateLast ) {
      let firstDate = new Date(dateFirst * 1000)
      let secondDate = new Date(dateLast * 1000)
      let firstYear = eventDateFormatYear(firstDate);
      let secondYear = eventDateFormatYear(secondDate);
      dateLabel = (firstYear === secondYear) ? firstYear : `${firstYear}-${secondYear}`
    } else if (dateFirst !== undefined) {
      let firstDate = new Date(dateFirst * 1000)
      dateLabel = eventDateFormatYear(firstDate)
    }
    return dateLabel;
  }

  switch (props.reltype) {
  case "event":
      return <div className="item">
          <div className="grid-item-sm relationship-item"><a className="rel-item-link1" onMouseUp={itemMouseUpHandler} onFocus={itemFocusHandler} onMouseDown={itemMouseDownHandler} onKeyUp={itemKeyUpHandler} href="javascript:;">
          <div className="grid-image-container-sm">
              <figure>
                  <div className="icon icon-relations-list icon-boxed jd-icon-event"></div>
                  <figcaption>{getTimespanLabel(props.result._source.timespan) || 'D.U.'}</figcaption>
              </figure>
          </div>
          <div className="grid-relations-text">
              <div className="grid-relations-text-b fourLineEllipsis">{props.result._source.primary_name.label || 'No name'}</div>
          </div>
          </a></div>
      </div>
      break;
  case "person":
      return <div className="item">
          <div className="grid-item-sm relationship-item"><a className="rel-item-link1" onMouseUp={itemMouseUpHandler} onFocus={itemFocusHandler} onMouseDown={itemMouseDownHandler} onKeyUp={itemKeyUpHandler} href="javascript:;">
          <div className="grid-image-container-sm">
              <figure>
                  <div className="icon icon-relations-list icon-boxed icon-bottom jd-icon-human-mid"></div>
                  <figcaption> </figcaption>
              </figure>
          </div>
          <div className="grid-relations-text">
              <div className="grid-relations-text-b fourLineEllipsis">{props.result._source.primary_name.label || 'No name'}</div>
          </div>
          </a></div>
      </div>
      break;
  case "org":
      return <div className="item">
          <div className="grid-item-sm relationship-item"><a className="rel-item-link1" onMouseUp={itemMouseUpHandler} onFocus={itemFocusHandler} onMouseDown={itemMouseDownHandler} onKeyUp={itemKeyUpHandler} href="javascript:;">
          <div className="grid-image-container-sm">
              <figure>
                  <div className="icon icon-relations-list icon-boxed jd-icon-org-md"></div>
                  <figcaption> </figcaption>
              </figure>
          </div>
          <div className="grid-relations-text">
              <div className="grid-relations-text-b fourLineEllipsis">{props.result._source.primary_name.label || 'No name'}</div>
          </div>
          </a></div>
      </div>
      break;
  case "location":
      switch (props.subtype) {
      case "map":
          return <div className="item">
              <div className="grid-item-sm relationship-item"><a className="rel-item-link1" onMouseUp={itemMouseUpHandler} onFocus={itemFocusHandler} onMouseDown={itemMouseDownHandler} onKeyUp={itemKeyUpHandler} href="javascript:;">
              <div className="grid-image-container-sm bigcitymap">
                  <figure>
                      <div className="icon icon-relations-tall icon-boxed jd-icon-city"></div>
                      <figcaption> </figcaption>
                  </figure>
              </div>
              <div className="grid-relations-text">
                  <div className="grid-relations-text-b fourLineEllipsis">{props.result._source.primary_name.label || 'No name'}</div>
              </div>
              </a></div>
            </div>
          break;
      default:
          return <div className="item">
              <div className="grid-item-sm relationship-item"><a className="rel-item-link1" onMouseUp={itemMouseUpHandler} onFocus={itemFocusHandler} onMouseDown={itemMouseDownHandler} onKeyUp={itemKeyUpHandler} href="javascript:;">
              <div className="grid-image-container-sm">
                  <figure>
                      <div className="icon icon-relations-list icon-boxed jd-icon-bldgs"></div>
                      <figcaption> </figcaption>
                  </figure>
              </div>
              <div className="grid-relations-text">
                  <div className="grid-relations-text-b fourLineEllipsis">{props.result._source.primary_name.label || 'No name'}</div>
              </div>
              </a></div>
          </div>
          break;
      }
      break;
  case "document":
      // console.log('document:'+props.result._source.primary_name.label);
      const pictureUrlDoc = props.result._source.representation?.length > 0 ? props.result._source.representation[0].link : '';
      const stylesDoc = {
          backgroundImage: `url(${pictureUrlDoc})`,
          backgroundColor: '#eaf0f4',  // == $jd-grey-blue-1
          backgroundSize: 'cover',
          backgroundPosition: 'center'
      };
      return (
      <div className="item">
          <div className="grid-item-sm relationship-item"><a className="rel-item-link1" onMouseUp={itemMouseUpHandler} onFocus={itemFocusHandler} onMouseDown={itemMouseDownHandler} onKeyUp={itemKeyUpHandler} href="javascript:;">
          <div className="grid-image-container-sm" style={stylesDoc}>

              { (pictureUrlDoc === '') &&
              <figure>
                  <div className="icon icon-relations-list jd-icon-no-doc">
                      <div className="grid-docs-no-img-avail">No Image Available</div>
                  </div>
                  <figcaption><span className="sr-only">No image available</span></figcaption>
              </figure>
              }

              { (pictureUrlDoc !== '') &&
              <figure>
                  <div className="icon icon-transparent"></div>
                  <figcaption className="transbg"><span className="sr-only">Document: {props.result._source.primary_name.label || 'No name'}</span></figcaption>
              </figure>
              }
              
          </div>
          <div className="grid-relations-text">
              <div className="grid-relations-text-b fourLineEllipsis">{props.result._source.primary_name.label || 'No name'}</div>
          </div>
          </a></div>
      </div>
      )
      break;
  case "artwork":
      // console.log('artwork:'+props.result._source.primary_name.label);
      const pictureUrl = props.result._source.representation?.length > 0 ? props.result._source.representation[0].link : '';
      const styles = {
          backgroundImage: `url(${pictureUrl})`,
          backgroundColor: '#eaf0f4',  // == $jd-grey-blue-1
          backgroundSize: 'cover',
          backgroundPosition: 'center'
      };
      return (
      <div className="item">
          <div className="grid-item-sm relationship-item"><a className="rel-item-link1" onMouseUp={itemMouseUpHandler} onFocus={itemFocusHandler} onMouseDown={itemMouseDownHandler} onKeyUp={itemKeyUpHandler} href="javascript:;">
          <div className="grid-image-container-sm" style={styles}>

              { (pictureUrl === '') &&
              <figure>
                  <div className="icon icon-relations-list icon-boxed jd-icon-frame-minus">
                      <div className="grid-artworks-no-img-avail">No Image Available</div>
                  </div>
                  <figcaption><span className="sr-only">No image available</span></figcaption>
              </figure>
              }

              { (pictureUrl !== '') &&
              <figure>
                  <div className="icon icon-transparent"></div>
                  <figcaption className="transbg"><span className="sr-only">Artwork: {props.result._source.primary_name.label || 'No name'}</span></figcaption>
              </figure>
              }

          </div>
          <div className="grid-relations-text">
              <div className="grid-relations-text-b fourLineEllipsis">{props.result._source.primary_name.label || 'No name'}</div>
          </div>
          </a></div>
      </div>
      )
      break;
  default:
      return <div>None</div>
      break;
  }
}

const RelationshipsTab = (props, group_limit=20, person_limit=3) => {

    const [ endpoint, setEndpoint ] = useState(props.endpoint);
    const [ ident, setIdent ] = useState(props.ident);
    const [ query, setQuery ] = useState(relationshipsContextToQuery(props.ident));

    const results = useElasticSearch(endpoint, query);

    const groups = (results !== null && results.hits.total.value > 0) ? results.hits.hits.filter(item => { return item._source.display_type === 'Group' }) : [];

    const persons = (results !== null && results.hits.total.value > 0) ? results.hits.hits.filter(item => { return item._source.display_type === 'Person' }) : [];
    // console.log('persons:'); console.log(persons);

    const artworks = (results !== null && results.hits.total.value > 0) ? results.hits.hits.filter(item => { return item._source.display_type === 'Artwork' }) : [];
    // console.log('artworks:'); console.log(artworks);

    const locations = (results !== null && results.hits.total.value > 0) ? results.hits.hits.filter(item => { return item._source.display_type === 'Location' }) : [];
    // console.log('locations:'); console.log(locations);

    const events = (results !== null && results.hits.total.value > 0) ? results.hits.hits.filter(item => { return item._source.display_type === 'TimelineEvent' }) : [];
    // console.log('events:'); console.log(events);

    const documents = (results !== null && results.hits.total.value > 0) ? results.hits.hits.filter(item => { return item._source.display_type === 'Document' }) : [];
    // console.log('documents:'); console.log(documents);

    // if < 0, will be ignored
    const sliderLimit = -1; //30;

    // need a "none" message when there are no relationships
    const somethingToDisplay = (groups?.length > 0
      || persons?.length > 0
      || artworks?.length > 0
      || locations?.length > 0
      || events?.length > 0
      || documents?.length > 0);

    return (
          <div
            className={`tab-pane clearfix fade ${props.show ? 'show' : ''} ${props.active ? 'active' : ''}`}
            id="relationships"
            role="tabpanel"
            aria-labelledby="relationships-tab"
            aria-expanded={props.active}
            aria-hidden={!props.active}
          >
            <div className="person-page-tab-pane-left">

            {/* <div className="row bg-white px-5 mx-0 border-bottom">
              <div className="col relationships-date-row align-items-center">
                <div className="d-inline-block pr-2">
                  <div className="dropdown-left-label">Date Range</div>
                </div>
                <div className="dropdown d-inline-block">
                  <button
                    type="button"
                    className="btn btn-link dropdown-toggle"
                    data-toggle="dropdown"
                    aria-label="Date Range"
                    id="dateButton"
                  >All</button>
                  <div className="dropdown-menu" aria-labelledby="dateButton">
                    <a className="dropdown-item" href="#" aria-current="true">All</a>
                    <a className="dropdown-item" href="#">Link 1</a>
                    <a className="dropdown-item" href="#">Link 2</a>
                    <a className="dropdown-item" href="#">Link 3</a>
                  </div>
                </div>
              </div>
            </div> */}

            {(!somethingToDisplay) &&
            <div className="row bg-white mx-0 px-1 py-3 align-items-start">
              <div className="col-12 p-2">
                <div className="p-5">None</div>
              </div>
            </div>
            }

            {(events.length > 0) &&
            <div className="row bg-white mx-0 pl-1 py-3 align-items-start">

              <div className="col-2 p-2">
                {/* NUMBER OF RELATED EVENTS */}
                <div className="relations-cluster py-5">
                  <div className="icon icon-lg icon--jd-grey jd-icon-events"></div>
                  <h3 className="relations-big-count">
                    <div className="text-center">{events.length}</div>
                    <div className="icon jd-icon-relation-sep"></div>
                    <div className="relationship__section-title">Related<div className="related-subhead">Events</div></div>
                  </h3>
                </div>
              </div>
              <div className="col-10 px-2">
                <div className="row bg-white mx-0">
                      <MultiCarouselR itemWidth={112}>
                      {
                        // if array length exceeds the slider limit, truncate and add a "view all" item:
                        (sliderLimit > 0
                          ? events.slice(0, sliderLimit)
                          : events).concat(sliderLimit > 0 && events.length > sliderLimit ? [{ viewall: events.length }] : [])
                          .map((hit, idx) => {
                            return <RelationshipListItem
                                itemClickAction={props.itemClickAction}
                                storeActive={props.storeActive}
                                reltype={'event'}
                                key={idx}
                                result={hit}
                            />
                        })
                      }
                      </MultiCarouselR>
                </div>
              </div>

            </div>
            }

            {(persons.length > 0) &&
            <div className="row bg-white mx-0 pl-1 py-3 align-items-start">

              <div className="col-2 p-2">
                {/* NUMBER OF RELATED PEOPLE */}
                <div className="relations-cluster py-5">
                  <div className="icon icon-lg icon--jd-grey jd-icon-users-mask"></div>
                  <h3 className="relations-big-count">
                    <div className="text-center">{persons.length}</div>
                    <div className="icon jd-icon-relation-sep"></div>
                    <div className="relationship__section-title">Related<div className="related-subhead">People</div></div>
                  </h3>
                </div>
              </div>
              <div className="col-10 px-2">
                <div className="row bg-white mx-0">
                      <MultiCarouselR itemWidth={112}>
                      {
                        // if array length exceeds the slider limit, truncate and add a "view all" item:
                        (sliderLimit > 0
                          ? persons.slice(0, sliderLimit)
                          : persons).concat(sliderLimit > 0 && persons.length > sliderLimit ? [{ viewall: persons.length }] : [])
                          .map((hit, idx) => {
                            return <RelationshipListItem
                                itemClickAction={props.itemClickAction}
                                storeActive={props.storeActive}
                                reltype={'person'}
                                key={idx}
                                result={hit}
                            />
                        })
                      }
                      </MultiCarouselR>
                </div>
              </div>

            </div>
            }

            {/* Note: don't show orgs if type is Group -- see issue #72 in github */}
            {(props.type !== 'Group' && groups.length > 0) &&
            <div className="row bg-white mx-0 pl-1 py-3 align-items-start">

              <div className="col-2 p-2">
                {/* NUMBER OF RELATED ORGS */}
                <div className="relations-cluster py-5">
                  <div className="icon icon-lg icon--jd-grey jd-icon-org-mask"></div>
                  <h3 className="relations-big-count">
                    <div className="text-center">{groups.length}</div>
                    <div className="icon jd-icon-relation-sep"></div>
                    <div className="relationship__section-title">Related<div className="related-subhead">Organi&shy;zations</div></div>
                  </h3>
                </div>
              </div>
              <div className="col-10 px-2">
                <div className="row bg-white mx-0">
                      <MultiCarouselR itemWidth={112}>
                      {
                        // if array length exceeds the slider limit, truncate and add a "view all" item:
                        (sliderLimit > 0
                          ? groups.slice(0, sliderLimit)
                          : groups).concat(sliderLimit > 0 && groups.length > sliderLimit ? [{ viewall: groups.length }] : [])
                          .map((hit, idx) => {
                            return <RelationshipListItem
                                itemClickAction={props.itemClickAction}
                                storeActive={props.storeActive}
                                reltype={'org'}
                                key={idx}
                                result={hit}
                            />
                        })
                      }
                      </MultiCarouselR>
                </div>
              </div>

            </div>
            }

            {(locations.length > 0) &&
            <div className="row bg-white mx-0 pl-1 py-3 align-items-start">

              <div className="col-2 p-2">
                {/* NUMBER OF RELATED LOCATIONS */}
                <div className="relations-cluster py-5">
                  <div className="icon icon-lg icon--jd-grey icon-pin-mask"></div>
                  <h3 className="relations-big-count">
                    <div className="text-center">{locations.length}</div>
                    <div className="icon jd-icon-relation-sep"></div>
                    <div className="relationship__section-title">Related<div className="related-subhead">Locations</div></div>
                  </h3>
                </div>
              </div>
              <div className="col-10 px-2">
                <div className="row bg-white mx-0">
                      <MultiCarouselR itemWidth={112}>
                      {
                        // for subtype: what condition do addresses satisfy that cities/towns/etc. do not?
                        // if array length exceeds the slider limit, truncate and add a "view all" item:
                        (sliderLimit > 0
                          ? locations.slice(0, sliderLimit)
                          : locations).concat(sliderLimit > 0 && locations.length > sliderLimit ? [{ viewall: locations.length }] : [])
                          .map((hit, idx) => {
                            const subtype = (hit._source.classified_as && hit._source.classified_as.length > 0
                                && hit._source.classified_as.map(d => d.label.toLowerCase()).includes('address')
                                ? 'bldgs'
                                : 'map');
                            return <RelationshipListItem
                                itemClickAction={props.itemClickAction}
                                storeActive={props.storeActive}
                                reltype={'location'}
                                subtype={subtype}
                                key={idx}
                                result={hit}
                            />
                        })
                      }
                      </MultiCarouselR>
                </div>
              </div>

            </div>
            }

            {/* Note: don't show artworks if type is Artist -- see issue #77 in github */}
            {(props.type !== 'Artist' && artworks.length > 0) &&
            <div className="row bg-white mx-0 pl-1 py-3 align-items-start">

              <div className="col-2 p-2">
                {/* NUMBER OF RELATED ARTWORKS */}
                <div className="relations-cluster py-5">
                  <div className="icon icon-lg icon--jd-grey jd-icon-frame"></div>
                  <h3 className="relations-big-count">
                    <div className="text-center">{artworks.length}</div>
                    <div className="icon jd-icon-relation-sep"></div>
                    <div className="relationship__section-title">Related<div className="related-subhead">Artworks</div></div>
                  </h3>
                </div>
              </div>
              <div className="col-10 px-2">
                <div className="row bg-white mx-0">
                      <MultiCarouselR itemWidth={112}>
                      {
                        // if array length exceeds the slider limit, truncate and add a "view all" item:
                        (sliderLimit > 0
                          ? artworks.slice(0, sliderLimit)
                          : artworks).concat(sliderLimit > 0 && artworks.length > sliderLimit ? [{ viewall: artworks.length }] : [])
                          .map((hit, idx) => {
                            return <RelationshipListItem
                                itemClickAction={props.itemClickAction}
                                storeActive={props.storeActive}
                                reltype={'artwork'}
                                key={idx}
                                result={hit}
                            />
                        })
                      }
                      </MultiCarouselR>
                </div>
              </div>

            </div>
            }

            {(documents.length > 0) &&
            <div className="row bg-white mx-0 pl-1 py-3 align-items-start">

              <div className="col-2 p-2">
                {/* NUMBER OF RELATED DOCUMENTS */}
                <div className="relations-cluster py-5">
                  <div className="icon icon-lg icon--jd-grey jd-icon-doc-mask"></div>
                  <h3 className="relations-big-count">
                    <div className="text-center">{documents.length}</div>
                    <div className="icon jd-icon-relation-sep"></div>
                    <div className="relationship__section-title">Related<div className="related-subhead">Documents</div></div>
                  </h3>
                </div>
              </div>
              <div className="col-10 px-2">
                <div className="row bg-white mx-0">
                      <MultiCarouselR itemWidth={112}>
                      {
                        // if array length exceeds the slider limit, truncate and add a "view all" item:
                        (sliderLimit > 0
                          ? documents.slice(0, sliderLimit)
                          : documents).concat(sliderLimit > 0 && documents.length > sliderLimit ? [{ viewall: documents.length }] : [])
                          .map((hit, idx) => {
                            return <RelationshipListItem
                                itemClickAction={props.itemClickAction}
                                storeActive={props.storeActive}
                                reltype={'document'}
                                key={idx}
                                result={hit}
                            />
                        })
                      }
                      </MultiCarouselR>
                </div>
              </div>

            </div>
            }

            </div> {/* END OF tab-pane-left */}

            <div className="person-page-tab-pane-right">
              {/* SHIM to make space for the QV above the footer (when necessary) */}
              <div className={props.shimHidden ? 'd-none' : ''} ref={props.shimRef} style={{height: `${props.shimHeight}px`}} id="qvShim"></div>
            </div>

          </div>
    )
}

export { RelationshipsTab };