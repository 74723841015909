import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

import { useElasticSearch, essaysTabContextToQuery } from './util.js';

const EssaysTab = (props) => {
    const [endpoint, setEndpoint] = useState(props.endpoint);
    const [query, setQuery] = useState(essaysTabContextToQuery(props.ident));
    const results = useElasticSearch(endpoint, query);
    // console.log('results:'); console.log(results);

    const essays = ((results !== null && results.hits.total.value > 0) ? results.hits.hits.filter(item => { return item._source.display_type === 'Essay' }) : []).sort( (a,b) => Number(a._source.sequence) - Number(b._source.sequence) );
    const glossarys = ((results !== null && results.hits.total.value > 0) ? results.hits.hits.filter(item => { return item._source.display_type === 'Glossary' }) : []).sort( (a,b) => Number(a._source.primary_name.label) - Number(b._source.primary_name.label) );

    // console.log('essays:'); console.log(essays);

    const somethingToDisplay = (essays?.length > 0 || glossarys?.length > 0);

    return (
        <div
          className={`tab-pane clearfix fade ${props.show ? 'show' : ''} ${props.active ? 'active' : ''}`}
          id="essays"
          role="tabpanel"
          aria-labelledby="essays-tab"
          aria-expanded={props.active}
          aria-hidden={!props.active}
        >
          {(!somethingToDisplay) &&
          <div className="row bg-white mx-0 px-1 py-3 align-items-start">
            <div className="col-12 p-2">
              <div className="p-5">None</div>
            </div>
          </div>
          }

          {(essays.length > 0 || glossarys.length > 0 ) &&
          <div className="container-fluid">
              <div className={`essay-column-main ${glossarys.length > 0 ? 'pb-1' : ''}`}>
                {
                  essays.map( (hit,idx) => 
                    <React.Fragment key={idx}>
                      <p className="essays-link-p"><a href={hit._id.replace('http://www.collectionbrowse.org','')}>{ hit._source.primary_name.label ?? ''}</a></p>
                      <p className={`pub-byline-tab ${hit._source?.author?.length > 0 ? '' : 'd-none' }`}>By {hit._source?.author?.map( auth => auth.label ).join(', ') ?? '' }{ hit._source?.creation_date?.date_label ? ` | ${hit._source?.creation_date?.date_label}` : ''}</p>
                      <p className="essays-p">{hit._source?.description?.html ?? ''}</p>
                    </React.Fragment>
                  )
                }
              </div>
              {(glossarys.length > 0) &&
              <div className={`essay-column-main ${essays.length > 0 ? 'pt-2' : 'pt-0'}`}>
                <div className={`subsection-section ${essays.length > 0 ? 'subsection-notes-section' : 'mt-0'}`}>
                  <h3 className="subsection-section-heading">Glossary</h3>
                  <p className="essays-glossary-section">
                    <ul>
                      {
                        glossarys.map( (hit, idx) => (
                          <li>{hit._source.primary_name.label}
                            <p>{ parse(DOMPurify.sanitize(hit._source.description.html)) }</p>
                          </li>
                        ) )
                      }
                    </ul>
                  </p>
                  <p className="essays-glossary-section mt-4">
                    <a className="essays-seeall" href="/essays/glossary">See all glossary entries</a>
                  </p>
                </div>
              </div>
              }
          </div>
          }

        </div>
    )
}

export { EssaysTab };