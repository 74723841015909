import React, {useState} from 'react';
import { useElasticSearch, searchContextToQuery } from '../util.js';
import TypeaheadResultsList from './typeahead';

function SearchBar(props) {
    /* 
    A simple SearchBar component that just constructs a URI for us to load next after entry.
    The idea is that this is standalone such that running singly as a component is expected behavior. 
    FIXME: Do we need type-ahead suggestions?
        - Names
        - Locations
        - Text from lots of other places 
        Up to 3 of each, total of 7-9
    FIXME: Actually load the page when we hit enter / click
    */

    const [ endpoint, setEndpoint ] = useState(props.endpoint);
    const [ entityType, setEntityType ] = useState("Location");
    const [ queryString, setQueryString ] = useState(props.queryString);
    const pageSize = 10;
    const [ query, setQuery ] = useState(searchContextToQuery(props.queryString, {}, entityType,pageSize));
    const [ isOpen, setIsOpen ] = useState(false);
    const results = useElasticSearch(endpoint, query);

    function blurHandler(event) {
        // delay in case we're clicking a link in the typeahead results
        // -- don't want it to close too soon
        setTimeout(() => {
            setIsOpen(false);
        }, 500);
    }

    function changeHandler(event) {
        let newQueryString = event.target.value;
        // FIXME: We may need more than simple JSON safety?
        console.log(newQueryString);
        if ( newQueryString.length > 2 ) {
            setIsOpen(true);
            setQueryString(newQueryString);
            console.log(searchContextToQuery(newQueryString, {},entityType,pageSize));
            setQuery(searchContextToQuery(newQueryString, {}, entityType,pageSize));
        } else {
            setIsOpen(false);
        }
    }

    function handleEnterKeypress(event) {
        event.preventDefault()

        setIsOpen(false);
        const searchUrl = new URL('/search',window.location.href)
        const params = new URLSearchParams();

        params.set('s',queryString)
        searchUrl.search = params.toString()
        console.log('sup')
        window.location.href = searchUrl.href
    }

    return <div className={props.searchBarClassName}>
        <form action="/" onSubmit={handleEnterKeypress}>
            <input type="text" placeholder="" aria-label="Search" name="search" onBlur={blurHandler} onChange={changeHandler} />
            {props.searchButton &&
            <button type="submit " className="btn btn-link">
                <span className="icon icon-search"></span>
                <p className="sr-only">Submit Search</p>
            </button>
            }
        </form>
        { (isOpen && results) ? <TypeaheadResultsList results={ (results.hits) ? results.hits.hits : [] } /> : ''}
    </div>

}

export { SearchBar };