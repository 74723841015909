import React, {useState, useEffect, useContext} from 'react';
import EntityThumbnail from './components/entityThumbnail';
import { truncateWordBoundary, eventNormalize } from './util.js';

const RESULTS_MODE = {
  GRID: 'GRID',
  LIST: 'LIST'
}

const ResultsPageControl = (props) => {
  // console.log(props);

  /* FIXME: Some quick kludgery to put this in before we port filters */
  let firstPageAvail = props.fromIndex > 0
  let prevPageAvail = props.fromIndex > 0

  let nextPageAvail = ( props.fromIndex + props.pageSize ) < props.count
  let lastPageAvail = ( props.fromIndex + props.pageSize ) < props.count

  let currentPage = Number(props.fromIndex / props.pageSize) + 1;
  let totalPage = Math.ceil(props.count / props.pageSize);

  function nextPageHandler(event) {
    if (nextPageAvail) {
      let idx = props.fromIndex + props.pageSize
      props.changePage(idx) 
    }
  }

  function prevPageHandler(event) {
    if (prevPageAvail) {
      let idx = ( props.fromIndex - props.pageSize > 0 ) ? props.fromIndex - props.pageSize : 0
      props.changePage(idx)
    }

  }

  function lastPageHandler(event) {
    if (lastPageAvail) {
      let lastPage = Math.floor( props.count / props.pageSize )
      let lastPageIndex = lastPage * props.pageSize
      props.changePage(lastPageIndex)
    }
  }

  function firstPageHandler(event) {
    if (firstPageAvail) {
      props.changePage(0)
    }
  }

  function sortbyHandler(event) {
    props.changeSort(event.target.value);
  }
  /* End Kludgery */

  const sortbyOptions = {
    'Artwork': [{
          'value': 'nameaz',
          'label': 'Name (A-Z)'
        }, {
          'value': 'nameza',
          'label': 'Name (Z-A)'
        }, {
          'value': 'creatornameaz',
          'label': 'Artist name'
        }],
    'Person': [{
          'value': 'nameaz',
          'label': 'Name (A-Z)'
        }, {
          'value': 'nameza',
          'label': 'Name (Z-A)'
        }, {
          'value': 'lifedates',
          'label': 'Life dates'
        }],
    'default': [{
          'value': 'nameaz',
          'label': 'Name (A-Z)'
        }, {
          'value': 'nameza',
          'label': 'Name (Z-A)'
        }]
    };

  const mySortbyOptions = (['Artwork', 'Person'].includes(props.type))
    ? sortbyOptions[props.type]
    : sortbyOptions['default'];

    return <div className="landing-page__table-controls">
        <div className="landing-page__table-controls-left d-flex align-items-center">
          {!props.basiclayout &&
          <div className="landing-page__table-sort d-flex align-items-center">
            {/* <div className="landing-page-sortby px-2">Sort by: </div> */}
            <div className="dropdown">
              <select
                className="form-select form-control search-type-dropdown search-results px-2"
                aria-label="Sort by"
                onChange={sortbyHandler}
              >
                <optgroup label="Sort by">
                {
                  mySortbyOptions.map((hit) => {
                    return (<option value={hit.value} selected={(props.sortSetting === hit.value) ? '1' : null}>{hit.label}</option>);
                  })
                }
                </optgroup>
              </select>
            </div>
          </div>
          }
          { (props.count < props.pageSize) &&
          <div className={`landing-page__table-showing ${(props.basiclayout) ? 'ml-0' : ''} d-none d-md-block`}>
            Showing{" "}
            <span className="landing-page__table-showing-total">{props.count}</span> {props.count === 1 ? 'result' : 'results'}
          </div>
          }
          { !(props.count < props.pageSize) &&
          <div className={`landing-page__table-showing ${(props.basiclayout) ? 'ml-0' : ''} d-none d-md-block`}>
            Showing{" "}
            <span className="landing-page__table-showing-numbers">{props.fromIndex + 1}-{Math.min((props.fromIndex + props.pageSize), props.count)}</span> of{" "}
            <span className="landing-page__table-showing-total">{props.count}</span> {props.count === 1 ? 'result' : 'results'}
          </div>
          }
        </div>
        <div className="landing-page__table-controls-right d-flex">
          {
          false && !props.basiclayout &&
          <div className="landing-page__table-view">
            <div className="landing-page__table-view-text">View</div>
            <div className="viewbtn-container">
              <button
                className="btn border-0 viewbtn"
                type="button"
                onClick={props.resultsModeGrid}
                data-toggle="tooltip"
                data-placement="top"
                title="Grid view"
              >
                <span className={`icon jd-icon-grid icon-lg ${props.mode === RESULTS_MODE.GRID ? 'active' : ''}`}></span>
              </button>
            </div>
            <div className="viewbtn-container">
              <button
                className="btn border-0 viewbtn"
                type="button"
                onClick={props.resultsModeList}
                data-toggle="tooltip"
                data-placement="top"
                title="List view"
              >
                <span className={`icon jd-icon-list icon-lg ${props.mode === RESULTS_MODE.LIST ? 'active' : ''}`}></span>
              </button>
            </div>
          </div>
          }
          { !(props.count < props.pageSize) &&
          <div className="landing-page__table-pagination">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item" disabled={!firstPageAvail}>
                  <a className="page-link"
                    href={firstPageAvail ? 'javascript:;' : null}
                    aria-label="First page" 
                    onClick={firstPageHandler}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Go to first page"
                  >
                    <span
                      aria-hidden="false"
                      className={`icon icon-end-carat-left ${firstPageAvail ? 'icon--teal' : 'icon--jd-grey'}`}
                    ></span>
                    <span className="sr-only">First page</span>
                  </a>
                </li>
                <li className="page-item" disabled={!prevPageAvail}>
                  <a className="page-link"
                    href={prevPageAvail ? 'javascript:;' : null}
                    aria-label="Previous page" 
                    onClick={prevPageHandler}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Go to previous page"
                  >
                    <span
                      aria-hidden="false"
                      className={`icon icon-carat-left ${prevPageAvail ? 'icon--teal' : 'icon--jd-grey'}`}
                    ></span>
                    <span className="sr-only">Previous page</span>
                  </a>
                </li>
                <li className="page-item">
                  <p className="landing-page__table-pagination-text">
                    <span className="landing-page__table-pagination-of">p</span>
                    <span className="landing-page__table-pagination-current">{currentPage}</span>
                    <span className="landing-page__table-pagination-of">of</span>
                    <span className="landing-page__table-pagination-number-text">{totalPage}</span>
                  </p>
                </li>
                <li className="page-item" disabled={!nextPageAvail}>
                  <a className="page-link"
                    href={nextPageAvail ? 'javascript:;' : null}
                    aria-label="Next page" 
                    onClick={nextPageHandler}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Go to next page"
                  >
                    <span
                      aria-hidden="false"
                      className={`icon icon-carat-right ${nextPageAvail ? 'icon--teal' : 'icon--jd-grey'}`}
                    ></span>
                    <span className="sr-only">Next page</span>
                  </a>
                </li>
                <li className="page-item" disabled={!lastPageAvail}>
                  <a className="page-link"
                    href={lastPageAvail ? 'javascript:;' : null}
                    aria-label="Last page" 
                    onClick={lastPageHandler}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Go to last page"
                  >
                    <span
                      aria-hidden="false"
                      className={`icon icon-end-carat-right ${lastPageAvail ? 'icon--teal' : 'icon--jd-grey'}`}
                    ></span>
                    <span className="sr-only">Last page</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          }
        </div>
      </div>
}

const ResultsView = (props) => {
  switch (props.mode) {
    case RESULTS_MODE.LIST:
      return <ResultsListView results={props.results} searchStr={props.searchStr} />
    case RESULTS_MODE.GRID:
      return <ResultsGridView results={props.results} searchStr={props.searchStr} />
  }
}

const ResultPill = (props) => {
  switch (props.classification.toLowerCase()) {
    case "male":
      return <div className="pill">M</div>
      break;
    case "female":
      return <div className="pill pill--female">F</div>
      break;
    case "unknown":
      return <div className="pill pull--unknown">U</div>
      break;
    default:
      return <div className="pill">{props.classification}</div>
      break;
  }
}

const ActivityDatesLocationsView = (props) => {
  return <td>
      {props.dataSource.map((activity,idx) => {
          return <React.Fragment key={idx}>
                  <span className="landing-page__table-locations">
                    {activity.location.label}
                  </span>
                  <h5 className="landing-page__table-year">{activity.date_label}</h5>
                  <br />
                </React.Fragment>
        }) 
      }
    </td>
}

const ClassificationIconsView = (props) => {

  const iconFromClassification = (c) => {
    switch (c.toLowerCase()) {
      case 'watercolorists':
      case 'painter':
      case 'figure painter':
      case 'portrait painter':
        return 'icon-painting'
        break;
      case 'sculptor':
        return 'icon-sculpture'
        break;
      case 'lithographer':
      case 'engraver':
      case 'etcher':
      case 'printmaker':
        return 'icon-printmaking'
        break;
      case 'illustrator':
      case 'cartoonist':
      case 'graphic artist':
        return 'icon-drawing'
        break;
      case 'silversmith':
        return 'icon-jewelry'
        break;      
      case 'leathersmith':
      case 'potter':
      case 'ceramicist':
        return 'icon-crafts'
        break;      
      case 'architect':
        return 'icon-architecture'
        break;      
      case 'silhouettist':
      case 'photographer':
        return 'icon-photography'
        break;    
      case 'interior designer':
        return 'icon-furniture'
        break;
      case 'textile designer':
        return 'icon-fashion'
        break;
      case 'woodcarver':
        return 'icon-woodworking'
        break;
      default :
        return 'icon-jewelry'
        break;
    }
  }

  return <td className="landing-page__table-col--entity-type">
      <div className="d-flex justify-content-between">
        {
          props.dataSource.slice(0,props.limit).map( item => {
            return <span>
                <span className={ `icon ${iconFromClassification(item.label)} icon-xxl`}></span>
                <span className="sr-only">Camera FIXME</span>                            
            </span>
          }) 
        }
      </div>
    </td>
}

const AdministrativeIconsView = (props) => {
  return <td className="landing-page__table-col--information">
      <div className="d-flex justify-content-between">
        <span>
          <span className="icon icon-property-file icon-xl"></span>
          <span className="sr-only">File available</span>
        </span>
        <span>
          <span className="icon icon-property-user icon-xl"></span>
          <span className="sr-only">Biography available</span>
        </span>
        <span>
          <span className="icon icon-property-image icon-xl"></span>
          <span className="sr-only">Image available</span>
        </span>
      </div>
    </td>
}

const getResultYearRangeString = (y0, y1) => {
  let s0 = '';
  let s1 = '';
  if (y0) {
    const d = y0['date_label'];
    if (d) {
      const n = parseInt(d.substr(0, 4));
      if (n >= 1000 && n < 10000) { // i.e. does the string begin w. 4 digit number?
        s0 = n;
      }
    }
  }
  if (y1) {
    const d = y1['date_label'];
    if (d) {
      const n = parseInt(d.substr(0, 4));
      if (n >= 1000 && n < 10000) { // i.e. does the string begin w. 4 digit number?
        s1 = n;
      }
    }
  }
  return (s0 + (s0 !== '' && s1 !== '' ? '-' : '') + s1);
}

const ResultListItem = (props) => {

    const ident = new URL(props.result._id);
    const itemLink = `${ident.pathname}/`;

    return <tr>
        <td className="landing-page__table-col--thumbnail">
          <EntityThumbnail type={`${Math.random() < 0.5 ? 'file' : 'city'}`} />
        </td>
        <td scope="row" className="landing-page__table-col--name">
          <div>
            <div className="landing-page__table-name">
              <a href={itemLink}>{props.result._source.primary_name.label}</a>
            </div>
            <div className="d-flex">
              { props.result._source.gender ? <ResultPill classification={props.result._source.gender.label}/> : '' }
              { props.result._source.ethnicity ? <ResultPill classification={props.result._source.ethnicity.label}/> : '' }
            </div>
          </div>
        </td>
        <td className="landing-page__table-col--entity-type td-pad">
          <div className="landing-page__table-col--heading">
            Artwork
          </div>
          <div>
            Painting
          </div>
        </td>
        <td className="landing-page__table-col--entity-dates td-pad">
          <div>
            Text text text
          </div>
        </td>
        <td className="landing-page__table-col--entity-about td-pad">
          <div>
            Text text text
          </div>
        </td>
        <td className="landing-page__table-col--entity-location td-pad">
          <div>
            Text text text
          </div>
        </td>
      </tr>
}

const ResultsListView = (props) => {

    if (props.count === 0 || props.results == null || props.results.hits.total.value < 1) { 
      return <div className="px-4">No results were found matching "{props.searchStr}"</div>
    } else {

      return <div className="landing-page__table-container">
          <table className="landing-page__table">
            <thead>
              <tr>
                <th scope="col" className="landing-page__table-col--thumbnail"><span className="sr-only">Icon</span></th>
                <th scope="col" className="landing-page__table-col--name">Name</th>
                <th scope="col" className="landing-page__table-col--entity-type">Type</th>
                <th scope="col" className="landing-page__table-col--dates">Dates</th>
                <th scope="col" className="landing-page__table-col--about">About</th>
                <th scope="col" className="landing-page__table-col--location">Location</th>
              </tr>
            </thead>
            <tbody>
                { 
                  props.results.hits.hits.map( (hit, idx) => {
                      return <ResultListItem key={idx} result={hit}/>
                  })
                }
            </tbody>
          </table>
        </div>
      }
}

const ItemFigure = (props) => {
  const cleanClassified = props.source?.classified_as?.filter( item => item.label !== "T3-major-events" && item.label !== "T1-Schloss-related" && item.label !== "T2-context");
  const classification = (cleanClassified && cleanClassified.length > 0) ? cleanClassified[0].label : '';
  const imageUrl = props.source.representation?.length > 0 ? props.source.representation[0].link : '';
  const imageUrlStyle = {
      backgroundImage: `url(${imageUrl})`,
      backgroundColor: '#eaf0f4',  // == $jd-grey-blue-1
      backgroundSize: 'cover',
      backgroundPosition: 'center'
  };
  const occupation = (props.source?.occupation && props.source?.occupation.length > 0) ? props.source?.occupation[0].label : '';

  switch (props.source.display_type) {
    case "Artwork":
      return ( imageUrl !== '' ) ? (   // show image, if there is one
        <figure style={imageUrlStyle}>
          <div className="icon icon-grid-tr icon-boxed"></div>
          <figcaption>
              <div className="grid-image-text-headline">
                {props.source.display_type}
              </div>
              <div className="grid-image-text-classification">
                {classification}
              </div>
          </figcaption>
        </figure>
      ) : (
        <figure>
          <div className="icon icon-grid-no-image icon-boxed jd-icon-frame-minus-grid">
              <div className="grid-results-no-img px-4">No Image Available</div>
          </div>
          <figcaption>
              <div className="grid-image-text-headline">
                {props.source.display_type}
              </div>
              <div className="grid-image-text-classification">
                {classification}
              </div>
          </figcaption>
        </figure>
      )
      break;
    case "Document":
      return ( imageUrl !== '' ) ? (   // show image, if there is one
        <figure style={imageUrlStyle}>
          <div className="icon icon-grid-tr icon-boxed"></div>
          <figcaption>
              <div className="grid-image-text-headline">
                {props.source.display_type}
              </div>
              <div className="grid-image-text-classification">
                {classification}
              </div>
          </figcaption>
        </figure>
      ) : (
        <figure>
          <div className="icon icon-grid-no-image icon-boxed jd-icon-no-doc-grid">
              <div className="grid-results-no-img px-4">No Image Available</div>
          </div>
          <figcaption>
              <div className="grid-image-text-headline">
                {props.source.display_type}
              </div>
              <div className="grid-image-text-classification">
                {classification}
              </div>
          </figcaption>
        </figure>
      )
      break;
    case "TimelineEvent":
      return (
        <figure>
          <div className="icon icon-grid-no-image icon-boxed jd-icon-event-grid">
              <div className="grid-results-no-img px-4"> </div>
          </div>
          <figcaption>
              <div className="grid-image-text-headline">
                Event
              </div>
              <div className="grid-image-text-classification">
                {classification}
              </div>
          </figcaption>
        </figure>
      )
      break;
    case "Group":
      return (
        <figure>
          <div className="icon icon-grid-no-image icon-boxed jd-icon-org-grid">
              <div className="grid-results-no-img px-4"> </div>
          </div>
          <figcaption>
              <div className="grid-image-text-headline">
                Organization
              </div>
              <div className="grid-image-text-classification">
                {classification}
              </div>
          </figcaption>
        </figure>
      )
      break;
    case "Location":
      const subtype = (props.source.classified_as && props.source.classified_as.length > 0
        && props.source.classified_as.map(d => d.label.toLowerCase()).includes('address')
        ? 'bldgs'
        : 'map');
      return (subtype === 'bldgs') ? (
        <figure>
          <div className="icon icon-grid-no-image icon-boxed jd-icon-bldgs-grid">
              <div className="grid-results-no-img px-4"> </div>
          </div>
          <figcaption>
              <div className="grid-image-text-headline">
                {props.source.display_type}
              </div>
              <div className="grid-image-text-classification">
                {classification}
              </div>
          </figcaption>
        </figure>
      ) : (
        <figure>
          <div className="icon icon-grid-no-image icon-boxed jd-icon-city-grid">
              <div className="grid-results-no-img px-4"> </div>
          </div>
          <figcaption className="solid-grey">
              <div className="grid-image-text-headline">
                {props.source.display_type}
              </div>
              <div className="grid-image-text-classification">
                {classification}
              </div>
          </figcaption>
        </figure>
      )
      break;
    case "Person":
      return (
        <figure>
          <div className="icon icon-grid-no-image icon-boxed icon-bottom-grid jd-icon-human-grid">
              <div className="grid-results-no-img px-4"> </div>
          </div>
          <figcaption>
              <div className="grid-image-text-headline">
                {props.source.display_type}
              </div>
              <div className="grid-image-text-classification">
                { classification || occupation }
              </div>
          </figcaption>
        </figure>
      )
      break;
    default:
      return (
        <figure>
          <div className="icon icon-grid-no-image icon-boxed">
              <div className="grid-results-no-img-avail px-3">No Image Available</div>
          </div>
          <figcaption>
              <div className="grid-image-text-headline">
                {props.source.display_type}
              </div>
              <div className="grid-image-text-classification">
                {classification}
              </div>
          </figcaption>
        </figure>
      )
      break;
  }
}

const ResultGridItem = (props) => {

  const ident = new URL(props.result._id);
  const itemLink = `${ident.pathname}/`;

  const displayType = props.result._source.display_type;
  var descriptionShort = (props.result._source.description && truncateWordBoundary(props.result._source.description.text, 120)) ||
            'No description available';
  if (props.result._source.creator && props.result._source.creator.length > 0) {
    descriptionShort = props.result._source.creator.reduce( (agg, newVal) => { return agg + (agg === '' ? '' : ', ') + `${newVal.label}` }, '');
  }

  return <a tabindex="-1" href={itemLink}><div>
      <div className="grid-image-container">
        <ItemFigure source={props.result._source} />
      </div>
      <div className="grid-date-bar">
        { // only show data for Events
        (displayType === "TimelineEvent")
          ? props.result.date_label
          : ' '
        }
      </div>
      <div className="grid-text-below">
        <div className="landing-page__grid-name">
          <a href={itemLink}>{props.result._source.primary_name.label}</a>
        </div>
        <div className="landing-page__grid-desc">
          { descriptionShort }
        </div>
        <div className="landing-page__grid-desc grid-recovery-status">
        </div>
      </div>
    </div></a>
}

const ResultsGridView = (props) => {
  if (props.count === 0 || !props.results || !props.results.hits || props.results.hits.total.value < 1) {
    return <div className="px-4">No results were found matching "{props.searchStr}"</div>
  } else {
    return <div className="landing-page__grid">
        <ul className="landing-page__grid__list">
          { 
              props.results.hits.hits.map((hit, idx) => {
                  return <li key={idx}><ResultGridItem result={hit._source.display_type === 'TimelineEvent' ? eventNormalize(hit, 'en', true) : hit} /></li>
              })
          }
        </ul>
      </div>
  }
}

const ResultsContextView = (props) => {
    return <div className="col-sm-3"><code>{props.aggregations}</code></div>
}

export { ResultsPageControl, ResultsView, RESULTS_MODE };