import React, {useState, useEffect, useContext} from 'react';

import { ResultsPageControl, ResultsView, RESULTS_MODE } from './results.js';
import { useElasticSearch, searchContextToQuery, paramsToState, stateToParams } from './util.js';

import { FilterBar } from './components/filterBar.js';

const YouSearched = (props) => {
  let typeLabel = '';
  switch (props.entityType) {
    case "Person":
      typeLabel = "People";
      break;
    case "Artwork":
      typeLabel = "Art Objects";
      break;
    case "Group":
      typeLabel = "Organizations";
      break;
    case "TimelineEvent":
      typeLabel = "Events";
      break;
    case "Document":
      typeLabel = "Documents";
      break;
    default:
      typeLabel = props.entityType;
      break;
  }
  return props.searchStr === '' ? (
    <div className="landing-page-beanstalk-bg">
      <h1 className="you-searched-text title">
        {typeLabel}
      </h1>
    </div>
  ) : (
    <div className="landing-page-beanstalk-bg">
      <h1 className="you-searched-text">
        You Searched For
      </h1>
      <div className="you-searched-user-string">
        “{props.searchStr}”
      </div>
    </div>
  );
}

const SearchView = (props) => {
  //console.log('search view; entity type = ' + props.type + '; endpoint = ' + props.endpoint + '; queryString = ' + props.queryString);
  //console.log('filters = ' + props.filters);
  //console.log(props);

    const [ entityType, setEntityType ] = useState(props.type);
    const [ endpoint, setEndpoint ] = useState(props.endpoint);
    const [ queryString, setQueryString ] = useState(props.queryString);
    const [ filters, setFilters ] = useState(props.filters);
    const [ aggregations, setAggregations ] = useState({});
    const [ query, setQuery ] = useState(searchContextToQuery(props.queryString, props.filters, props.type, 50, 0, props.sortTerm ?? "primary_name.label.sort", props.sortDir ?? "asc"));
    const [ count, setCount ] = useState(0);
    const [ fromIndex, setFromIndex ] = useState( props.fromIndex ?? 0);
    const [ pageSize, setPageSize ] = useState(50);
    const [ sortTerm, setSortTerm ] = useState( props.sortTerm ?? 'primary_name.label.sort')
    const [ sortDir, setSortDir ] = useState( props.sortDir ?? 'asc')
    const [ sortSetting, setSortSetting ] = useState( props.sortSetting ?? 'nameaz')
    
    const results = useElasticSearch(endpoint, query);

    const [ resMode, setResMode ] = useState(RESULTS_MODE.GRID);  // default mode

    useEffect(() => {
      // Responds to any change in any of our search-facing params and sets the query, params to match
      let params = stateToParams({queryString, fromIndex, pageSize, filters, sortTerm, sortDir, sortSetting})
      const url = new URL(window.location.href)
      url.search = params.toString()

      window.history.pushState({params: params.toString()},document.title,url)
      let query = searchContextToQuery(queryString, filters, entityType, pageSize, fromIndex, sortTerm, sortDir)
      setQuery(query)
    }, [queryString, fromIndex, pageSize, filters, sortTerm, sortDir, sortSetting]);

    const popStateHandler = (event) => {

      let state = event.state;
      var params = state?.params;
      console.log("Popped off of history",params,document.location.href)

      console.log(event)
      if (params) {
        console.log(params)
        for ( let [key,value] of Object.entries( paramsToState(params) ) ) {
          switch (key) {
            case 'filters':
              setFilters(value)
              break;
            case 'fromIndex':
              setFromIndex(Number(value))
              break
            case 'pageSize':
              setPageSize(Number(value))
              break
            case 'queryString':
              setQueryString(value)
            case 'sortTerm':
              setSortTerm(value)
            case 'sortDir':
              setSortDir(value)
            case 'sortSetting':
              setSortSetting(value)
            default: 
              continue
          }
        }

      }

    }

    // Adds a popstate handler since we'll need to catch our pushState()s on back-button presses 
    useEffect(() => {
      let href = window.location.href
      let params = window.location.search

      window.history.replaceState({params: params.toString()},document.title,href)

      window.onpopstate = (event) => { popStateHandler(event) } 
    }, []);

    // Responds to the results from useElasticSearch
    useEffect(() => {
        if (results === null) { return; }
        setCount(results?.hits?.total?.value ?? 0);
        setAggregations(results.aggregations);
    }, [results]);

    function changePage(newVal) {
        if ( newVal < count ) {
            setFromIndex(newVal);

            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            const lowerTop = $('.lower-page-start')?.offset()?.top;

            const topStuffHeight = 115;
            if (scrollTop >= lowerTop) {
              window.scrollTo(0, lowerTop + topStuffHeight);
            }
        }
    }

    function changeSort(newVal) {
      setSortSetting(newVal);
      // set page to first
      setFromIndex(0);

      let sort_term = "primary_name.label.sort";
      let sort_order = "asc";
      switch (newVal) {
        case 'nameza':
          sort_order = 'desc';
          break;
        case 'creatornameaz':
          sort_term = 'creator.label.sort';
          sort_order = 'asc';
          break;
        case 'lifedates':
          sort_term = 'birth.date_begin';
          sort_order = 'asc';
          break;
      }

      setSortDir(sort_order)
      setSortTerm(sort_term)

    }

    const applyFilters = (newFilters, unsets) => {
      let filt1 = {...filters, ...newFilters};
      for (const u of unsets) {
        delete filt1[u];
      }

      setFilters(filt1);

    }

    const clearFilter = (filt) => {
      applyFilters({},[filt]);
    }

    const clearFilters = () => {  
      setFilters({});
    }

    const clickForListMode = () => {
      setResMode(RESULTS_MODE.LIST);
    }

    const clickForGridMode = () => {
      setResMode(RESULTS_MODE.GRID);
    }

    return <div className="container-fluid p-0 m-0">
        <YouSearched searchStr={props.queryString} entityType={props.type} />
        <FilterBar entityType={entityType} endpoint={endpoint} currentFilters={filters} applyFilters={applyFilters} clearFilter={clearFilter} clearFilters={clearFilters} aggregations={aggregations} count={count} fromIndex={fromIndex} pageSize={pageSize} />
        <div className="row mx-0">
          <div className="col px-0">
              <ResultsPageControl changePage={changePage} changeSort={changeSort} sortSetting={sortSetting} count={count} fromIndex={fromIndex} pageSize={pageSize} mode={resMode} resultsModeList={clickForListMode} resultsModeGrid={clickForGridMode} type={props.type} />
              <ResultsView results={results} count={count} mode={resMode} searchStr={props.queryString} />                
              <ResultsPageControl changePage={changePage} changeSort={changeSort} sortSetting={sortSetting} count={count} fromIndex={fromIndex} pageSize={pageSize} mode={resMode} basiclayout={true} type={props.type} />
          </div>
        </div>
      </div>
}

export {SearchView};