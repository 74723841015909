import React, { useState, useRef, useEffect, useContext } from 'react';

const EntityTabNav = (props) => {
    return (
        <li className="nav-item">
          <a
            className={`nav-link ${props.active ? 'active' : ''} d-inline-block`}
            href="javascript:;"
            role="tab"
            id={`${props.nameRef}-tab`}
            aria-controls={props.nameRef}
            aria-selected={props.active}
            onClick={(event) => {
              // need to distinguish show & active for purposes of fade effect
              props.setShowTab(null);
              // delay for purpose of fade effect on tab content
              setTimeout(() => {
                props.setActive(props.nameRef);
                props.setShowTab(props.nameRef);
              }, 150);   // this should match the transition time on .fade
              event.currentTarget.focus();
            }}
            onFocus={() => props.focusfunc(props.myIdx)}
          ><h2 className="d-inline-block">{props.nameLabel}</h2></a>
        </li>
    )
}

export { EntityTabNav };